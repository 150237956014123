import React, { useContext, useEffect, useRef, useState } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ZenObservable } from "zen-observable-ts";
import { useIdleTimer } from "react-idle-timer";
import { Avatar } from "primereact/avatar";
import { OverlayPanel } from "primereact/overlaypanel";
import { TabMenu } from "primereact/tabmenu";
import { Toast } from "primereact/toast";
import { EyeControlIcon, ArrowRightIcon, RefreshIcon } from "src/icons";
import { checkPagePermission, isEnableView, setActiveTab } from "src/utils/generalMethods";
import { fetchDevices, fetchTenantVoices, logoutUser, fetchCurrentQuestions, clearToast, detachDevice, updateToast } from "src/actions";
import { fetchSchedulerEvents } from "src/actions/schedulerEvent.action";
import { getIcus } from "src/actions/icu.action";
import { StoreState } from "src/reducers";
import { interceptor } from 'src/apis/apiMiddleware';
import IotService from "src/services/iot.service";
import { GenericSubscriptionActions, GenericSubscriptionResources } from "src/constans/genericSubscription.enum";
import { ViewTypes } from "src/models/Authorization.model";
import { UserModel } from "src/models/user.model";
import { TabModel } from "src/models/tab.model";
import { TOAST_SEVERITY, ToastModel } from "src/models/toast.model";
import AmplifyService from "../../services/amplifyService";
import { UserMenu } from "./UserMenu";
import { DotsMenuIcon } from "../../icons/DotsMenuIcon";
import { Button } from "primereact/button";
import { DialogTypes, OpenDialogArgs } from "./CustomDialog";
import { useMixpanel } from "src/hooks/useMixpanel";
import { DialogContext } from "src/contexts/DialogContext";
import { DialogPropTypes } from "src/contexts/types";
import { DeviceActionsContext } from "src/contexts/DeviceActionsContext";

interface NavbarProps {
    currentUser?: UserModel;
    views?: ViewTypes[];
    fetchDevices: Function;
    fetchTenantVoices: Function;
    getIcus: Function;
    logoutUser: Function;
    fetchSchedulerEvents: Function;
    tenantId?: string;
    icuId?: string;
    enablePatientDeviceSubscription?: boolean;
    isSmallTablet?: boolean;
    toastHandler?: ToastModel;
    clearToast: Function;
    fetchCurrentQuestions: Function;
    showToast: Function;
    detachDevice: Function;
}

const OVERVIEW_PAGE_INDEX = 0;
const ROUTING_INDEX = 1;

const redirectToPage = (navigate: NavigateFunction, page: string) => {
    navigate(`/${page}`);
}

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, void, Action>) => {
  return {
    fetchDevices: () => {
      dispatch(fetchDevices());
    },
    fetchSchedulerEvents: () => {
      dispatch(fetchSchedulerEvents());
    },
    // TODO: Change the logic not to take from tenant
    fetchTenantVoices: (icuId: string) => {
      dispatch(fetchTenantVoices(icuId));
    },
    fetchCurrentQuestions: () => {
      dispatch(fetchCurrentQuestions());
    },
    getIcus: (jwtToken: string) => {dispatch(getIcus(jwtToken))},
    logoutUser: (navigate: NavigateFunction) => dispatch(logoutUser((page: string) => {redirectToPage(navigate, page)})),
    clearToast:() => dispatch(clearToast()),
    detachDevice: (deviceId: string, callBack: Function) => {
        dispatch(detachDevice(deviceId, callBack));
    },
    showToast: (ToastModel: ToastModel) =>
        dispatch(updateToast(ToastModel))
  };
};

const mapStateToProps = ({authInfo, icus, toastHandler}: StoreState) => {
    return {
        currentUser: (authInfo?.currentUser as UserModel),
        views: authInfo?.authorization?.views,
        tenantId: icus[0]?.tenant_id,
        icuId: icus[0]?._id,
        enablePatientDeviceSubscription: icus[0]?.feature_flags?.events?.enable_patient_device_subscription,
        toastHandler,
        isSmallTablet: icus[0]?.feature_flags?.isSmallTablet
    };
};

const {DEVICE} = GenericSubscriptionResources
const {CREATE, DELETE} = GenericSubscriptionActions

const _Navbar = ({ 
  currentUser, 
  fetchDevices, 
  fetchTenantVoices, 
  getIcus, 
  logoutUser, 
  fetchSchedulerEvents,
  views,
  tenantId,
  icuId,
  enablePatientDeviceSubscription,
  isSmallTablet,
  fetchCurrentQuestions,
  toastHandler,
  showToast,
  detachDevice,
  clearToast
}: NavbarProps): JSX.Element => {
    const {t} = useTranslation();
    const [activeIndex, setActiveIndex] = useState(0);
    const toast = useRef<Toast>(null);
    const subscriptionRef = useRef<ZenObservable.Subscription | undefined>(undefined);
    const navigate: NavigateFunction = useNavigate();
    const op = useRef<OverlayPanel>(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const deviceActionsContext = useContext(DeviceActionsContext);
    const { trackOpenMoreActionMenu } = useMixpanel()
    const pagesWithPermission = useSelector((state: StoreState) => state?.authInfo?.authorization?.permissions)?.pages;
    const isOverviewPage = location.pathname.includes('/overview');
    const isNurseView = isEnableView(ViewTypes.NURSE, views);
    const isRegularView = isEnableView(ViewTypes.REGULAR, views);
    const { showDialog, hide, hideAttentionDialog } = React.useContext(DialogContext) as DialogPropTypes;
    const isNurseViewAndOverviewPage = isNurseView && isOverviewPage
    const isNurseViewAndOverviewPageOrNotNurseView = isNurseViewAndOverviewPage || !isNurseView
    const isPatientDeviceSubscriptionEnabled = isNurseViewAndOverviewPageOrNotNurseView && enablePatientDeviceSubscription
    const isSmallTableFlag = isNurseView && isSmallTablet;
    const [deviceId, setDeviceId] = useState<string | undefined>(location.pathname.split('/')[2]);

    let navTabs: TabModel[] = [
        { label: t("Overview"), link: "/overview", pageName: "Overview" },
    ];

    if (isRegularView) {
        navTabs.push({ label: t("Patient View"), link: "/patientview", pageName: "PatientView" });
        navTabs.push({ label: t("Settings"), link: "/settings", pageName: "Settings" });
    }

    navTabs.filter(navTab => checkPagePermission(navTab.pageName, pagesWithPermission || []));

    const handleOnIdle = () => {
        console.log('User is idle. Last active', getLastActiveTime());
        logoutUser(() => {
            navigate(`/login`);
            window.location.reload(); // hack to achieve Amplify logStreamName has email in it
        });
    }

    const {getLastActiveTime} = useIdleTimer({
        timeout: process.env.REACT_APP_IDLE_TIME as unknown as number,
        onIdle: handleOnIdle,
    })

    const redirect = (index: number) => {
        if (activeIndex !== index) {
            setActiveIndex(index);
            navigate(navTabs[index].link);
        }
    };

    // To do it  dynamic.
    let user = {
        name: currentUser?.email || "",
        email: currentUser?.email || "",
        imgUrl: "user.png",
    };

    const reconnectIcuGenericTopic = (
        tenantId: string, icuId: string
    ) => {
        setTimeout(() => {
            subscribeToGenericIcuTopic(tenantId, icuId);
        }, 10000);
    };

    const showRefreshToast = () => {
        const handlePageRefresh = () => {
            window.location.reload();
        }

        toast?.current?.replace({
            sticky: true,
            className: "toast-refresh",
            content: () => (
                <div className="toast-refresh__content-container">
                    <RefreshIcon className="toast-refresh__icon" />
                    <div className="toast-refresh__text__container">
                        <div className="toast-refresh__title">Refresh Required</div>
                        <span className='toast-refresh__text'>
                            <span className='toast-refresh__text__link' onClick={handlePageRefresh}>{t('Click here')}</span>
                            {t('to sync with latest changes')}
                        </span>
                    </div>
                </div>
            )
        })
    }

    const subscribeToGenericIcuTopic = (tenantId: string, icuId: string) => {
        const topic = `${tenantId}/${icuId}/generic`;
        const sessionId = localStorage.getItem("sessionId");

        return IotService.subscribeToTopic(topic,
            async (data: any) => {
                const { resource, action, payload } = data.value
                const isEventFromCurrentUser = sessionId === payload?.sessionId

                if(isEventFromCurrentUser) return;

                if (action === CREATE) {
                    switch (resource) {
                        case DEVICE:
                            showRefreshToast()
                            return;
                        default:
                            return;
                    }
                } else if (action === DELETE) {
                    switch (resource) {
                        case DEVICE:
                            showRefreshToast()
                            return;
                        default:
                            return;
                    }
                }
            },
            (err: any) => {
                console.log(`Error: ${JSON.stringify(err)}`);
            },
            () => {
                reconnectIcuGenericTopic(tenantId, icuId);
            }
        );
    }

    useEffect(() => {
        AmplifyService.triggerStoredLogs();
        AmplifyService.connectivityCheck(navigate, dispatch);
    }, []);

    useEffect(() => {
        setActiveTab(ROUTING_INDEX, activeIndex, navTabs, setActiveIndex, location);
        setDeviceId(location.pathname.split('/')[2]);
    }, [location.pathname]);

    useEffect(() => {
        // TODO: support for several groups
        if (!currentUser?.groups) return;

        fetchTenantVoices(currentUser.groups);
    }, [currentUser?.groups]);

    useEffect(() => {
        if (currentUser?.token) {
            interceptor();
            getIcus(currentUser.token);
        }
    }, [currentUser?.token]);

    useEffect(() => {
        if(icuId) {
            fetchDevices();
            fetchSchedulerEvents();
            fetchCurrentQuestions();
        }
    }, [icuId])

    useEffect(() => {
        const isSubscriptionExistingAndNotClosed = !!subscriptionRef.current && !subscriptionRef.current?.closed;

        if (tenantId && icuId && isPatientDeviceSubscriptionEnabled && !isSubscriptionExistingAndNotClosed) {
            subscriptionRef.current = subscribeToGenericIcuTopic(tenantId, icuId);
        }

        if (subscriptionRef.current && !isPatientDeviceSubscriptionEnabled) {
            subscriptionRef.current.unsubscribe();
        }

        return () => {
            if (subscriptionRef.current) subscriptionRef.current.unsubscribe();
        };
    }, [tenantId, icuId, isPatientDeviceSubscriptionEnabled]);

    useEffect(() => {
        if (!!toastHandler?.severity) {
            toast?.current?.show(toastHandler);
        }

        return () => {
            clearToast()
        }
    }, [toastHandler]);

    const openDialog = (deviceId: string | undefined) => {
        if (!deviceId) {
            console.log("Device id is undefined");
            return
        }
        const args: OpenDialogArgs = {
            dialogType: DialogTypes.ACTION_DIALOG,
            title: t("patientDischarge"),
            component: (
                <div>
                    <div>
                        {t("dischargeDialogDescription")}
                    </div>
                    <div>{t("areYouSure")}</div>
                </div>
            ),
            actionCallback: async () => {
                detachDevice(deviceId, ((result: boolean) => {
                    if (result) {
                        showToast({
                            severity: TOAST_SEVERITY.SUCCESS,
                            summary: t("Success"),
                            detail: t("The patient was discharged."),
                        });
                        hide();
                        deviceActionsContext.setDetachedDeviceId?.(deviceId);
                        hideAttentionDialog();
                    }
                    else {
                        showToast({
                            severity: TOAST_SEVERITY.ERROR,
                            summary: t("Something went wrong!"),
                            detail: t("The patient wasn't discharged")
                        });  
                    }
                }));
            },
            cancelCallback: () => {
                console.log("Action Canceled");
            },
            actionText: t("Discharge"),
            cancelText: t("Cancel"),
            shouldNotHide: true
        };

        if (deviceId) {
            showDialog(args);
        }
    };

    const getNearToBedMainActions = (deviceId: string | undefined) => {
        return (
            <div className="patient-action-menu__near-bed-buttons-wrapper">
                <Button
                    onClick={(e) => op.current?.toggle(e)}
                    className="patient-action-menu__action-button button button-x button-near-bed"
                >
                    {t("X")}
                </Button>
                <Button
                    onClick={() => openDialog(deviceId)}
                    className="patient-action-menu__action-button button button-near-bed"
                >
                    {t("Discharge Patient")}
                </Button>
            </div>
        );
    }

    const onCloseMenu = () => {
        op.current?.hide();
    };

    const onOpenMenu = (event: React.MouseEvent) => {
        op.current?.toggle(event)
        trackOpenMoreActionMenu()
    }

    return (
        <nav className={`navbar ${isNurseView ? "navbar navbar-near-bed" : ""}`}>
            <UserMenu actions={{logout: logoutUser}} user={user} op={op}/>
            <div className="navbar__item-group">
                <div className="navbar__icon-box">
                    <EyeControlIcon/>
                </div>
                {isRegularView
                    ? <TabMenu model={navTabs} activeIndex={activeIndex} onTabChange={(e) => redirect(e.index)}
                               className="navbar__tabs"/>
                    : null
                }
            </div>
            {icuId && (
                isNurseViewAndOverviewPageOrNotNurseView
                    ? (
                        <div className="navbar__profile-box">
                            <div onClick={op.current?.toggle} className="navbar__profile-box-inner">
                                <Avatar icon="pi pi-user" className="navbar__profile-image p-avatar-circle" size="large"
                                        shape="circle"/>
                                <div className="navbar__profile-name">{currentUser?.email}</div>
                            </div>
                        </div>
                    )
                    : isSmallTableFlag 
                        ? (
                            <>
                                <div className="navbar__near-to-bed navbar__near-to-bed__end" onClick={onOpenMenu}>
                                    <button className="navbar__near-to-bed__more-btn navbar__near-to-bed--right">
                                        <DotsMenuIcon/>
                                    </button>
                                    <div className="navbar__near-to-bed__back-text">
                                        {t("More")}
                                    </div>
                                </div>
                                <OverlayPanel
                                    onHide={onCloseMenu}
                                    className={`patient-action-menu__near-bed-panel__small-tablet`}
                                    ref={op}
                                >
                                    {getNearToBedMainActions(deviceId)}
                                </OverlayPanel>
                            </>
                        )
                        : (
                            <div className="navbar__near-to-bed" onClick={() => redirect(OVERVIEW_PAGE_INDEX)}>
                                <div className="navbar__near-to-bed__back-text">
                                    {t("All Patients")}
                                </div>
                                <button className="navbar__near-to-bed__arrow-btn navbar__near-to-bed--right">
                                    <ArrowRightIcon width="20" height="25" stroke="#FFFFFF" fill="#0F284C"/>
                                </button>
                            </div>
                        )
            )}
            <div className="toast-handler">
                <Toast ref={toast}/>
            </div>
        </nav>
    );
};

export const Navbar = connect(mapStateToProps, mapDispatchToProps)(_Navbar);