import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MoonIcon } from "src/icons/MoonIcon";
import { StoreState } from "src/reducers";

type Props = {
    isNurseView: boolean;
    visible: boolean;
    onHide: () => void;
};

export const SleepDialog = ({ isNurseView, visible, onHide }: Props) => {
    const { t } = useTranslation();
    const { icus } = useSelector((state: StoreState) => ({
        icus: state.icus
    }));

    const sleepStart = icus[0]?.settings?.sleepStart;
    const sleepEnd = icus[0]?.settings?.sleepEnd;
    
    const formatTimeToAMPM = (time: string | undefined) => {
        if (!time) return '';
        const [hours, minutes] = time.split(':');
        const hour = parseInt(hours);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 || 12;
        return `${formattedHour}:${minutes} ${ampm}`;
    };

    const formattedSleepStart = formatTimeToAMPM(sleepStart);
    const formattedSleepEnd = formatTimeToAMPM(sleepEnd);
    
    return (
        <div className="sleep-dialog__content-wrapper">
            <MoonIcon className="sleep-dialog__icon" />
            <div className="sleep-dialog__title-text">
                {t("EyeControl Break Time")}
            </div>
            <p>
                <span className="sleep-dialog__time">{t("After")} {formattedSleepStart}- </span>
                <span className="sleep-dialog__instructions">{t("Remove the headset from the patient.")}</span>
            </p>
            <p>
                <span className="sleep-dialog__time">{t("At")} {formattedSleepEnd}- </span>
                <span className="sleep-dialog__instructions">{t("Place it back on and ensure the device is on.")}</span>
            </p>
        </div>
    );
};

export default SleepDialog; 