import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { store } from 'src/Root';
import { StoreState } from "src/reducers";
import { detachDevice, updateToast } from 'src/actions';
import { isEnableView, stopCommunication } from 'src/utils/generalMethods';
import { ArrowLeftIcon, ArrowRightIcon} from 'src/icons';
import { DialogContext } from 'src/contexts/DialogContext';
import { DialogPropTypes } from 'src/contexts/types';
import communicationApi from 'src/apis/communication';
import { communicatonType } from 'src/constans/communicationEnum';
import { PatientActions } from 'src/constans/patientActions';
import { SenderSource } from 'src/constans/senderSourceEnum';
import { ActionSubMenuTypes } from 'src/models/general.model';
import { IcuFeatureFlagsModel } from 'src/models/icu.model';
import { DeviceModel } from 'src/models/device.model';
import { QuestionModel } from 'src/models/question.model';
import { TOAST_SEVERITY, ToastModel } from 'src/models/toast.model';
import { ViewTypes } from 'src/models/Authorization.model';
import { UpsertPatientForm } from 'src/components/forms/UpsertPatientForm';
import { DialogTypes, OpenDialogArgs } from 'src/components/general/CustomDialog';
import AmplifyService from "src/services/amplifyService";
import { useMixpanel } from "src/hooks/useMixpanel";

interface PetientActionMenuProps {
    deviceId: string | undefined;
    featureFlags: IcuFeatureFlagsModel;
    showToast: Function;
    detachDevice: Function;
    views?: ViewTypes[];
    questions?: QuestionModel[];
}

const SlotsMapping: Record<string, number> = {
    "Relaxing": -1
}

const mapStateToProps = ({questions, authInfo}: StoreState) => {
    return {
        questions,
        views: authInfo?.authorization?.views || [],
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, void, Action>) => {
    return {
        detachDevice: (deviceId: string, callBack: Function) => {
            dispatch(detachDevice(deviceId, callBack));
        },
        showToast: (ToastModel: ToastModel) =>
            dispatch(updateToast(ToastModel))
    };
};

const _PetientActionMenu: React.FC<PetientActionMenuProps> = ({
    deviceId,
    featureFlags,
    views,
    showToast,
    detachDevice,
    questions
}: PetientActionMenuProps): JSX.Element => {
    const { t } = useTranslation();
    const op = useRef<OverlayPanel>(null);
    const { showDialog, hide } = React.useContext(DialogContext) as DialogPropTypes;
    const navigate: NavigateFunction = useNavigate();
    const {
        trackOpenMoreActionMenu, trackCloseMoreActionMenu, trackCloseEditPatientDetailsModalXButton,
        trackOpenEditPatientDetailsModal, trackOpenStopCommunicationModal, trackCloseStopModalUsingCloseButton,
        trackStopCommunicationModalClickStop
    } = useMixpanel()

    const [activeSection, setActiveSection] = useState<ActionSubMenuTypes>(ActionSubMenuTypes.MAIN);

    const slots = [1,2,3,4,5,6,7,8,9,10,"Relaxing"];

    const isRegularViewType = isEnableView(ViewTypes.REGULAR, views)

    const closeMenu = () => {
        op.current?.hide();
    };

    const sendFamilyMessage = async () => {
        if (deviceId) {
            await communicationApi.post("sendCommunication", {
                deviceId,
                communicationTypeId: communicatonType.SEND_FAMILY_MESSAGE,
                source: SenderSource.DASHBOARD
            });
        }
    };

    const sendInstructions = async () => {
        if (deviceId) {
            AmplifyService.log(`sendInstructions to ${deviceId}`);
            await communicationApi.post("sendCommunication", {
                deviceId,
                communicationTypeId: communicatonType.SEND_INSTRUCTIONS,
                source: SenderSource.DASHBOARD
            });
        }
    };

    const sendOrientation = async () => {
        if (deviceId) {
            AmplifyService.log(`sendOrientation to ${deviceId}`);
            await communicationApi.post("sendCommunication", {
                deviceId,
                instanceId: "62e688c5d471274f11f556df",
                communicationTypeId: communicatonType.SEND_ORIENTATION,
                source: SenderSource.DASHBOARD
            });
        }
    };

    const sendRelaxingMusic = async () => {
        if (deviceId) {
            AmplifyService.log(`sendRelaxingMusic to ${deviceId}`);
            await communicationApi.post("sendCommunication", {
                deviceId,
                communicationTypeId: communicatonType.SEND_MUSIC,
                genre: "radio_uk",
                source: SenderSource.DASHBOARD
            });
        }
    };

    const sendPersonalMusic = async () => {
        if (deviceId) {
            AmplifyService.log(`sendPersonalMusic to ${deviceId}`);
            await communicationApi.post("sendCommunication", {
                deviceId,
                communicationTypeId: communicatonType.SEND_PERSONAL_MUSIC,
                source: SenderSource.DASHBOARD
            });
        }
    };

    const sendCamIcu = async () => {
        if (deviceId) {
            AmplifyService.log(`sendCamIcu to ${deviceId}`);
            await communicationApi.post("sendCommunication", {
                deviceId,
                communicationTypeId: communicatonType.SEND_CAM_ICU,
                source: SenderSource.DASHBOARD
            });
        }
    };

    const sendTraining = async () => {
        if (deviceId) {
            AmplifyService.log(`sendTraining to ${deviceId}`);
            await communicationApi.post("sendCommunication", {
                deviceId,
                communicationTypeId: communicatonType.SEND_TRAINING,
                source: SenderSource.DASHBOARD
            });
        }
    };

    const sendGeneralMessage = async (slot: number | string) => {
        let slotNumber: number;
        if(Number.isInteger(slot)) {
            slotNumber = slot as number;
        } else {
            slotNumber = SlotsMapping[slot as string];
        }

        if (deviceId) {
            closeMenu();
            AmplifyService.log(`sendGeneralMessage to ${deviceId}`);
            await communicationApi.post("sendCommunication", {
                deviceId,
                communicationTypeId: communicatonType.SEND_GENERAL_MESSAGE,
                subOption: slotNumber,
                source: SenderSource.DASHBOARD
            });
        }
    };

    const sendQnA = async (id: string) => {
        if (deviceId) {
            closeMenu();
            AmplifyService.log(`sendQnA to ${deviceId}`);
            await communicationApi.post("sendCommunication", {
                deviceId,
                communicationTypeId: communicatonType.SEND_Q_N_A,
                subOption: id,
                source: SenderSource.DASHBOARD
            });
        }
    };

    const showSection = async (actionSubMenu: ActionSubMenuTypes) => {
        setActiveSection(actionSubMenu);
    };

    const moveToDeviceSettings = () => {
        if (deviceId) {
            navigate(`/settings/devices/${deviceId}`);
        }
    };

    const editPatientsDetails = () => {
        if (!deviceId) {
            console.log("Device id is undefined");
            return;
        }
        const device: DeviceModel | undefined = store
            .getState()
            ?.deviceObject.devices.find((device) => device.id === deviceId);
        const args: OpenDialogArgs = {
            dialogType: DialogTypes.FORM_DIALOG,
            title: t("Edit Patient Details"),
            component: (
                <UpsertPatientForm
                    type={PatientActions.UPDATE}
                    device={device}
                    onClose={() => hide()}
                />
            ),
            actionText: t("Save"),
            cancelText: t("Cancel"),
            cancelCallback: trackCloseEditPatientDetailsModalXButton
        };

        trackOpenEditPatientDetailsModal()
        showDialog(args);
    };

    const openStopCommunicationModal = () => {
        stopCommunication({
            deviceId,
            showDialog,
            t,
            source: SenderSource.DASHBOARD,
            cancelCB: trackCloseStopModalUsingCloseButton,
            actionCB: trackStopCommunicationModalClickStop
        });
        trackOpenStopCommunicationModal();
        closeMenu();
    }

    const onGetALinkClick = () => {
        showDialog({
          dialogType: DialogTypes.LINK_DIALOG,
          component: null,
          actionCallback: sendLink
        });
      };
    
      const sendLink = () => {
        hide();
      };

    const getMainActions = () => {
        return (
            <ul className="patient-action-menu__items">
                <li
                        className="patient-action-menu__item patient-action-menu__item--navigate"
                        onClick={() => {
                            showSection(
                                ActionSubMenuTypes.QNA
                            );
                        }}
                    >
                        <span className="patient-action-menu__item-name">
                            {t("Send Q&A")}
                        </span>
                        <span className="patient-action-menu__sub-icon">
                            <ArrowRightIcon />
                        </span>
                    </li>
                <li
                    className="patient-action-menu__item"
                    onClick={() => {
                        sendInstructions();
                        closeMenu();
                    }}
                >
                    <span className="patient-action-menu__item-name">
                        {t("Send Instructions")}
                    </span>
                </li>
                <li
                    className="patient-action-menu__item"
                    onClick={() => {
                        sendFamilyMessage();
                        closeMenu();
                    }}
                >
                    <span className="patient-action-menu__item-name">
                        {t("Send Family Messages")}
                    </span>
                </li>
                <li
                    className="patient-action-menu__item"
                    onClick={() => {
                        sendOrientation();
                        closeMenu();
                    }}
                >
                    <span className="patient-action-menu__item-name">
                        {t("Send Orientation")}
                    </span>
                </li>
                <li
                    className="patient-action-menu__item"
                    onClick={() => {
                        sendRelaxingMusic();
                        closeMenu();
                    }}
                >
                    <span className="patient-action-menu__item-name">
                        {t("Send Relaxing Music")}
                    </span>
                </li>
                <li
                    className="patient-action-menu__item"
                    onClick={() => {
                        sendPersonalMusic();
                        closeMenu();
                    }}
                >
                    <span className="patient-action-menu__item-name">
                        {t("Send Personal Music")}
                    </span>
                </li>
                <li
                    className="patient-action-menu__item"
                    onClick={() => {
                        sendCamIcu();
                        closeMenu();
                    }}
                >
                    <span className="patient-action-menu__item-name">
                        {t("Send CAM-ICU")}
                    </span>
                </li>
                <li
                    className="patient-action-menu__item"
                    onClick={() => {
                        sendTraining();
                        closeMenu();
                    }}
                >
                    <span className="patient-action-menu__item-name">
                        {t("Send Training")}
                    </span>
                </li>
                {featureFlags?.recordings
                    ?.general_recordings_management ? (
                    <li
                        className="patient-action-menu__item patient-action-menu__item--navigate"
                        onClick={() => {
                            showSection(
                                ActionSubMenuTypes.GENERAL_MESSAGES
                            );
                        }}
                    >
                        <span className="patient-action-menu__item-name">
                            {t("Send General Messages")}
                        </span>
                        <span className="patient-action-menu__sub-icon">
                            <ArrowRightIcon />
                        </span>
                    </li>
                ) : null}
                <li
                    className="patient-action-menu__item patient-action-menu__item--bordered"
                    onClick={() => {
                        moveToDeviceSettings();
                        closeMenu();
                    }}
                >
                    <span className="patient-action-menu__item-name">
                        {t("Device Settings")}
                    </span>
                </li>
                <li className="patient-action-menu__item" onClick={onGetALinkClick}>
                    <span className="patient-action-menu__item-name">
                        {t("Get a link")}
                    </span>
                </li>
                <li className="patient-action-menu__item" onClick={openStopCommunicationModal}>
                    <span className="patient-action-menu__item-name">
                        {t("Stop Communication")}
                    </span>
                </li>
                <li
                    className="patient-action-menu__item"
                    onClick={() => {
                        editPatientsDetails();
                        closeMenu();
                    }}
                >
                    <span className="patient-action-menu__item-name">
                        {t("Edit patient details")}
                    </span>
                </li>
            </ul>
        ); 
    }
      
    const getNearToBedMainActions = () => {
        return (
            <div className="patient-action-menu__near-bed-buttons-wrapper">
                <Button
                    onClick={(e) => op.current?.toggle(e)}
                    className="patient-action-menu__action-button button button-x button-near-bed"
                >
                    {t("X")}
                </Button>
                <Button
                    onClick={() => openDialog()}
                    className="patient-action-menu__action-button button button-near-bed"
                >
                    {t("Discharge Patient")}
                </Button>
            </div>
        );
    }

    const openDialog = () => {
        if (!deviceId) {
            console.log("Device id is undefined");
            return
        }
        const args: OpenDialogArgs = {
            dialogType: DialogTypes.ACTION_DIALOG,
            title: t("patientDischarge"),
            component: (
                <div>
                    <div>
                        {t("dischargeDialogDescription")}
                    </div>
                    <div>{t("areYouSure")}</div>
                </div>
            ),
            actionCallback: async () => {
                detachDevice(deviceId, ((result: boolean) => {
                    if (result) {
                        showToast({
                            severity: TOAST_SEVERITY.SUCCESS,
                            summary: t("Success"),
                            detail: t("The patient was discharged."),
                        });
                        hide();
                        navigate('/overview')
                    }
                    else {
                        showToast({
                            severity: TOAST_SEVERITY.ERROR,
                            summary: t("Something went wrong!"),
                            detail: t("The patient wasn't discharged")
                        });  
                    }
                }));
            },
            cancelCallback: () => {
                console.log("Action Canceled");
            },
            actionText: t("Discharge"),
            cancelText: t("Cancel"),
            shouldNotHide: true
        };

        if (deviceId) {
            showDialog(args);
        }
    };
    
    const showActions = () => {
        switch (activeSection) {
            case ActionSubMenuTypes.MAIN:
                if (isRegularViewType) {
                    return getMainActions();
                }

                return getNearToBedMainActions();
            case ActionSubMenuTypes.GENERAL_MESSAGES:
                return (
                    <ul className="patient-action-menu__items">
                        <li
                            className="patient-action-menu__item"
                            onClick={() => {
                                showSection(ActionSubMenuTypes.MAIN);
                            }}
                        >
                            <span className="patient-action-menu__item-name patient-action-menu__item-name--sub-title">
                                <span className="patient-action-menu__sub-icon">
                                    <ArrowLeftIcon />
                                </span>
                                <span className="patient-action-menu__sub-title-text">
                                    {t(" Send General Messages")}
                                </span>
                            </span>
                        </li>
                        {slots.map((slot) => (
                            <li
                                key={slot}
                                className="patient-action-menu__item"
                                onClick={() => {
                                    sendGeneralMessage(slot);
                                }}
                            >
                                <span className="patient-action-menu__item-name">
                                    {Number.isInteger(slot) ? t(`Messages slot ${slot}`) : t(`${slot} Slot`)}
                                </span>
                            </li>
                        ))}
                    </ul>
                );
            case ActionSubMenuTypes.QNA:
                return (
                    <ul className="patient-action-menu__items">
                        <li
                            className="patient-action-menu__item"
                            onClick={() => {
                                showSection(ActionSubMenuTypes.MAIN);
                            }}
                        >
                            <span className="patient-action-menu__item-name patient-action-menu__item-name--sub-title">
                                <span className="patient-action-menu__sub-icon">
                                    <ArrowLeftIcon />
                                </span>
                                <span className="patient-action-menu__sub-title-text">
                                    {t(" Send Q&A")}
                                </span>
                            </span>
                        </li>
                        {questions
                            ? questions.map((question) => (
                                  <li
                                      className="patient-action-menu__item"
                                      onClick={() => {
                                          sendQnA(question.id);
                                      }}
                                  >
                                      <span className="patient-action-menu__item-name">
                                          {question.name}
                                      </span>
                                  </li>
                              ))
                            : null}
                    </ul>
                );
            default:
                return null;
        }
    };

    const onHideMenu = () => {
        setActiveSection(ActionSubMenuTypes.MAIN);
        if(!isRegularViewType) trackCloseMoreActionMenu()
    }

    const onOpenMenu = (event: React.MouseEvent) => {
        op.current?.toggle(event)
        if(!isRegularViewType) trackOpenMoreActionMenu()
    }

    return (
        <>
            <Button
                onClick={onOpenMenu}
                className={`patient-action-menu__action-button button button-regular 
                ${isRegularViewType ? "" : "button-nurse"} `}
            >
                {isRegularViewType ? t("Actions") : t("More Actions")}
            </Button>
            <OverlayPanel
                onHide={onHideMenu}
                className={`patient-action-menu__overview${isRegularViewType ? '' : '-near-bed'}-panel`}
                ref={op}
            >
                {showActions()}
            </OverlayPanel>
        </>
    );
};

export const PetientActionMenu = connect(
    mapStateToProps,
    mapDispatchToProps
)(_PetientActionMenu);