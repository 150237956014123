import React from "react";

export const RelaxingMessageIcon = ({
    height = "2.4rem",
    width = "2.4rem",
    color = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} viewBox="0 0 62 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M55.3163 26.786C54.7301 27.8364 54.0374 28.8232 53.2492 29.7307C49.2895 34.4174 42.6793 37.3276 33.4185 38.4612C39.3443 41.641 44.6109 42.6779 49.2183 41.5719C53.9635 40.429 58.0679 36.9727 61.5314 31.2031L62 30.4012C61.2208 29.3281 60.2195 28.4368 59.0647 27.7883C57.9012 27.172 56.6167 26.8203 55.3025 26.7583L55.3163 26.786ZM42.7688 11.1844C44.5008 10.2516 46.3753 9.61389 48.3156 9.29733C50.6817 8.92249 53.0871 8.86669 55.4679 9.13143C57.1767 17.5693 56.0628 24.1016 52.126 28.7284C48.2995 33.2215 41.7904 35.9866 32.5986 37.0234C39.8817 29.7584 43.5612 23.0394 43.6232 16.749C43.648 14.8686 43.3496 12.9979 42.7413 11.219L42.7688 11.1844ZM21.4637 12.3181C22.6695 7.81805 26.0733 3.72585 31.6753 0C36.3539 3.45626 39.4614 7.06459 40.998 10.8388C40.9909 10.9939 41.0292 11.1477 41.1082 11.2812C41.1417 11.336 41.181 11.387 41.2254 11.4333C41.8479 13.1253 42.1539 14.918 42.128 16.7214C42.0385 22.7007 38.3796 29.1639 31.1034 36.3114C24.2129 28.6662 20.8573 21.9058 20.9469 15.9126C20.9588 14.7841 21.1045 13.6609 21.381 12.567C21.4145 12.4878 21.4354 12.4038 21.443 12.3181H21.4637ZM8.30296 9.11761C6.72275 17.4126 7.76321 23.8643 11.4243 28.4727C14.9936 32.9888 21.0847 35.8299 29.6977 36.9958C22.7728 29.1777 19.3621 22.1823 19.4379 15.878C19.452 14.7403 19.5859 13.6073 19.8375 12.4978C18.3057 11.1658 16.5089 10.1761 14.5663 9.59457C12.5303 9.02356 10.4027 8.85685 8.30296 9.10378V9.11761ZM0 29.7653C3.80351 36.0557 8.2019 39.8507 13.1952 41.1502C17.7888 42.3392 22.929 41.4175 28.6159 38.3852C20.0534 37.0856 13.9232 34.0902 10.2254 29.3989C9.4522 28.4244 8.78466 27.3701 8.23405 26.2537C6.69228 26.2031 5.15772 26.486 3.73461 27.0832C2.32102 27.7134 1.05028 28.626 0 29.7653Z" fill="#0F284C"/>
    </svg>
);
