import { TabModel } from "../models/tab.model";
import { Location } from "react-router-dom";
import { VoiceModel } from "../models/voice.model";
import { CommunicationErrorType, DeviceConnectionStatus, DeviceModel, DeviceShadowModel, DisconnectionReason } from "../models/device.model";
import amplifyService from '../services/amplifyService';
import { FamilyRecordingModel, RecordingSlotsModel, RecordingStatus } from "../models/recording.model";
import { PagePermissionModel, PermissionTypes, ResourceAction, ResourcePermissionModel, ViewTypes } from "../models/Authorization.model";
import { DialogTypes, OpenDialogArgs } from "../components/general/CustomDialog";
import React from "react";
import communicationApi from './../apis/communication';
import { communicatonType } from "../constans/communicationEnum";
import { SchedulerEvent } from "../models/scheduler.model";
import moment from "moment";
import { SenderSource } from "../constans/senderSourceEnum";

export const setActiveTab = (
  routingIndex: number,
  activeIndex: number,
  tabs: TabModel[],
  setActiveIndex: Function,
  location: Location
) => {
  let pathSections = location.pathname.split("/");
  let link = pathSections[routingIndex] ? pathSections[routingIndex] : null;
  let tabLink = tabs[activeIndex] ? tabs[activeIndex].link.replace('/', '') : null;

  if (link && link !== tabLink) {
    let index = tabs.findIndex((tab) => {
        let sectionLink = tab.link.replace('/', '');
        return sectionLink === link;
      });
    setActiveIndex(index);
  }
};

export const sortAndModifyVoicesToUpperCase = (
    items: VoiceModel[] | undefined
) => {
    if (!items) {
        return [];
    }
    return items.sort(sortVociesAlphabetically).map((item: VoiceModel) => {
        const name =
            item?.language?.name.charAt(0).toUpperCase() +
            item?.language?.name.slice(1);
        item.language.name = name;
        return item;
    });
};

const sortVociesAlphabetically = (voiceA: VoiceModel, voiceB: VoiceModel) => {
  if(voiceA.language.name < voiceB.language.name) { return -1; }
  if(voiceA.language.name > voiceB.language.name) { return 1; }
  return 0;
}

export const filterDevicesWithPatient = (devices: DeviceModel[] | undefined) => {
    if(!devices) return []

    return devices?.filter(device => device.patient);
}

export const isAlert = (device: DeviceModel) => {
    const presence = device?.shadow?.state?.presence;

    if (
        presence?.state === DeviceConnectionStatus.CONNECTED &&
        device?.shadow?.state?.is_healthy === false
    )
        return true;
    if (
        presence?.state === DeviceConnectionStatus.CONNECTED &&
        !device?.shadow?.state?.pwr
    )
        return true;
    if (
        presence?.state === DeviceConnectionStatus.CONNECTED &&
        device?.shadow?.state?.oop &&
        device?.shadow?.settings?.oop_dashboard_indication
    )
        return true;
    if (!device?.wifiStrength) return true;
    if (
        presence?.state === DeviceConnectionStatus.DISCONNECTED &&
        (presence?.disconnection_reason ===
            DisconnectionReason.CONNECTION_ERROR ||
            presence?.disconnection_reason ===
                DisconnectionReason.CONNECTION_LOST)
    ) {
        return true;
    }
    return false;
};

export const getShadowName = (topic: string) => {
    return topic.split('/')[3];
}

async function _createHyperlinkEvent(href: string, fileName: string) {
  try {
    // For blob URLs, use them directly
    if (href.startsWith('blob:')) {
      const link = document.createElement('a');
      link.href = href;
      link.download = fileName;
      link.click();
      
      // Clean up the blob URL after a short delay
      setTimeout(() => {
        URL.revokeObjectURL(href);
      }, 150);
      return link;
    }

    // For all other URLs, fetch and convert to blob
    const response = await fetch(href);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName;
    link.click();
    
    // Clean up
    setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
    }, 150);
    
    return link;
  } catch (error) {
    console.error('Download failed:', error);
    throw error;
  }
}

export function downloadBlob(blob: any , filename: string) {
  const url = URL.createObjectURL(blob);
  return _createHyperlinkEvent(url, filename);
}

export function downloadStream(blob: any , filename: string) {
  const url = blob;
  return _createHyperlinkEvent(url, filename);
}

export function downloadFromPresignedUrl (
  fileName: string,
  presignedUrl: string
) {
  return _createHyperlinkEvent(presignedUrl, fileName);
};
  
export async function getS3Item(pathWithoutOrigin: string | undefined) {
  if(pathWithoutOrigin) {
      const result = await amplifyService.getStorage.get(pathWithoutOrigin, 
        { download: true, level: "public" }
      );
      if (result.Body) {
          const splitedPath = pathWithoutOrigin.split('/');
          downloadBlob(result.Body, splitedPath[splitedPath.length - 1]);
      }
  }
}

export async function getS3ItemLink(pathWithoutOrigin: string | undefined, retries?: number): Promise<string | undefined> {
  if(pathWithoutOrigin) {
    try {
      const image = await amplifyService.getStorage.get(
        pathWithoutOrigin, 
        { download: true, level: "public", validateObjectExistence: true }
      );
      const file = new File([image.Body as Blob], 'temp-image-' + Date.now(), { type: "Blob" });
      return URL.createObjectURL(file);
    } catch (error) {
      if (retries && retries > 0) {
        await sleep(30000)
        return await getS3ItemLink(pathWithoutOrigin, retries - 1)
      }
    }
  }
  return undefined;
}

export function decodeJwt(token: string) {
  const [header, payload] = token.split('.');
  const decodedHeader = JSON.parse(atob(header));
  const decodedPayload = JSON.parse(atob(payload));

  return { decodedHeader, decodedPayload };
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function formatFloatAsTime (floatNumber: number): string  {
  const minutes = Math.floor(floatNumber);
  const seconds = Math.round((floatNumber - minutes) * 60);
  
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export function getSlotByNumber (numberOfSLot: number, slots: RecordingSlotsModel[]): RecordingSlotsModel | undefined  {
  return slots.find((slot: RecordingSlotsModel) => {
    return slot.number === numberOfSLot;
  })
};

export function sortFamilyRecordings (a: FamilyRecordingModel, b: FamilyRecordingModel): number {

  if (a.status === RecordingStatus.NOT_PLAYED && b.status !== RecordingStatus.NOT_PLAYED) {
    return -1;
  }
  if (a.status !== RecordingStatus.NOT_PLAYED && b.status === RecordingStatus.NOT_PLAYED) {
    return 1;
  }

  return new Date(b.creation_date).getTime() - new Date(a.creation_date).getTime();
};

export function sortByName(array: any[]) {
  return array.sort((a: any, b: any) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
}

export function checkResourcePermission(
    resource: ResourcePermissionModel | undefined,
    actionToCheck: ResourceAction
): boolean {

    if (!resource) {
        return true;
    }

    const { type, actions } = resource;

    if (!actions) {
        console.log("Actions are missing");
        return false;
    }

    switch (type) {
        case PermissionTypes.ALLOW:
            if (actions.includes(ResourceAction.ALL) || actions.includes(actionToCheck)) {
                return true;
            }

            return false;

        case PermissionTypes.DENY:
            if (actions.includes(ResourceAction.ALL) || actions.includes(actionToCheck)) {
                return false;
            }
            
            return true;
        default:
            return false;
    }
}

export function checkPagePermission(
  pageName: string,
  pagesPermission: PagePermissionModel[]
): boolean {
  if (!pageName) {
      return true;
  }

  const currentPageObj = getPage(pageName, pagesPermission || []);


  if (!currentPageObj) {
      return true;
  }

  if (currentPageObj?.type === PermissionTypes.DENY) {
      return false;
  }
  
  return true;
}

export function getPage(pageName: string,
  pagePermissions: PagePermissionModel[]) {
      return pagePermissions.find(
          (pagePerm) => pagePerm.name === pageName
      );
}

export const isEnableView = (viewToCheck: ViewTypes, userViews: ViewTypes[] | undefined) =>
  !!userViews && userViews.includes(viewToCheck)



const dialogContent = (t: Function) => {
    return React.createElement('div', null,
           React.createElement('div', null, t("You are about to stop the current communication playing to the patient.")),
           React.createElement('div', null, t("Are you sure?"))
    );
};

const sendStopCommunicationRequest = async (deviceId: string, source: SenderSource) => {

  amplifyService.log(`sendStopCommunicationRequest to ${deviceId}`);
  await communicationApi.post("sendCommunication", {
      deviceId,
      communicationTypeId: communicatonType.STOP_COMMUNICATION,
      source
  });
};

interface StopCommunicationProps {
    deviceId: string | undefined;
    showDialog: Function;
    t: Function;
    source: SenderSource;
    actionCB?: Function;
    cancelCB?: Function;
}

export function stopCommunication({deviceId, showDialog, t, source, cancelCB, actionCB}: StopCommunicationProps) {
    if (!deviceId) {
        console.log("Device id is undefined");
        return;
    }

    const args: OpenDialogArgs = {
        dialogType: DialogTypes.ACTION_DIALOG,
        title: "Stop Communication",
        component: () => dialogContent(t),
        actionCallback: async () => {
            if(actionCB) actionCB();
            await sendStopCommunicationRequest(deviceId, source);
            console.log("Action Happened");
        },
        cancelCallback: () => {
            if (cancelCB) cancelCB();
            console.log("Action Canceled");
        },
        actionText: t("Stop"),
        cancelText: t("Cancel"),
    };

    if (deviceId) {
        showDialog(args);
        console.log(`stopCommunication to ${deviceId}`);
    }
}

export function timeToMinutes(time: string) {
  const [hours, minutes] = time.split(":");
  return parseInt(hours) * 60 + parseInt(minutes);
}

export function getNextEventIndex(events: SchedulerEvent[], timeZone: string) {
  const today = moment().tz(timeZone || "");
  const currentTime = today.hours() * 60 + today.minutes();
  let closestTimeDifference = Infinity;
  let closestEventIndex = -1;

  for (let i = 0; i < events.length; i++) {
      const eventTime = events[i].time.split(":").map(Number);
      const eventMinutes = eventTime[0] * 60 + eventTime[1];
      const timeDifference = eventMinutes - currentTime;

      if (timeDifference > 0 && timeDifference < closestTimeDifference) {
          closestTimeDifference = timeDifference;
          closestEventIndex = i;
      }
  }

  return closestEventIndex;
}

export function capitalizeFirstLetter(input: string | undefined) {
  if (!input || input.length === 0) {
    return input;
  }
  
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function getDeviceConnectionError (deviceShadow: DeviceShadowModel) {

  if (!(deviceShadow?.state?.is_healthy || deviceShadow?.state?.is_healthy === undefined)) {
    return CommunicationErrorType.TECHNICAL;
  }

  if (
    deviceShadow?.state?.presence?.state as  DeviceConnectionStatus === DeviceConnectionStatus.DISCONNECTED &&
    (deviceShadow?.state?.presence?.disconnection_reason ===
        DisconnectionReason.CONNECTION_ERROR ||
        deviceShadow?.state?.presence?.disconnection_reason ===
            DisconnectionReason.CONNECTION_LOST)
  ) {
    return CommunicationErrorType.CONNECTION_ERROR;
  }

  if (
    deviceShadow?.state?.presence?.state as  DeviceConnectionStatus === DeviceConnectionStatus.DISCONNECTED &&
    deviceShadow?.state?.presence?.disconnection_reason ===
        DisconnectionReason.CLIENT_INITIATION
  ) {
    return CommunicationErrorType.DEVICE_OFF;
  }

  if (!(deviceShadow?.state?.pwr || deviceShadow?.state?.pwr === undefined)) {
    return CommunicationErrorType.POWER_NOT_CONNECTED;
}

  if (deviceShadow?.state?.presence?.state === DeviceConnectionStatus.CONNECTED) {
      return CommunicationErrorType.NONE;
  }

  return CommunicationErrorType.NONE;
};
