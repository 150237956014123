import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { DataTable, DataTableRowClickEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Skeleton } from 'primereact/skeleton';
import { getSamlUser } from "src/actions";
import {
    CommunicationIcon, OOPIcon, PowerNotConnectedIcon, WifiStrengthIcon, TechnicalErrorIcon
} from "src/icons";
import { StoreState } from "src/utils/testStore";
import { filterDevicesWithPatient, isEnableView, isAlert } from "src/utils/generalMethods";
import { PatientActions } from "src/constans/patientActions";
import { DialogPropTypes } from "src/contexts/types";
import { DialogContext } from "src/contexts/DialogContext";
import { DeviceModel, DeviceConnectionStatus, ActiveCommunicationType } from "src/models/device.model";
import { ViewTypes } from "src/models/Authorization.model";
import { UserModel } from "src/models/user.model";
import { useMixpanel } from "src/hooks/useMixpanel";
import { DialogTypes, OpenDialogArgs } from "src/components/general/CustomDialog";
import { UpsertPatientForm } from "src/components/forms/UpsertPatientForm";
import "primeicons/primeicons.css";

//Redux setup
interface OverviewProps {
    devices?: DeviceModel[] | undefined;
    isDevicesObjUpdated?: boolean | undefined;
    views? : ViewTypes[];
    setIsNearBedView: (value: boolean) => void;
}

const mapStateToProps = ({ authInfo, deviceObject }: StoreState) => {
    return {
        currentUser: (authInfo?.currentUser as UserModel),
        views: authInfo?.authorization?.views,
        devices: filterDevicesWithPatient(deviceObject.devices),
        isDevicesObjUpdated: deviceObject?.isDevicesObjUpdated || false
    };
};

const _OverviewTable = ({ devices, isDevicesObjUpdated, views, setIsNearBedView }: OverviewProps) => {
    const { t } = useTranslation();
    const navigate: NavigateFunction = useNavigate();
    const dispatch = useDispatch();
    const { trackOpenAddPatientModal, trackCloseAddPatientModalXButton, trackSaveAddPatientModal } = useMixpanel()
    const { showDialog, hide } = React.useContext(DialogContext) as DialogPropTypes;
    const location = useLocation();

    const HEIGHT = 3;
    const skeletonItems = [{height: HEIGHT},{height: HEIGHT},{height: HEIGHT},{height: HEIGHT},{height: HEIGHT}];

    const navigateToPatientView = (event: DataTableRowClickEvent) => {
        isEnableView(ViewTypes.NURSE, views) ? 
            navigate(`/neartobedpatientview/${event.data.id}`) :
            navigate(`/patientview/${event.data.id}`)
    };

    const patientNameBodyTemplate = (rawData: DeviceModel) => {
        return (
            <div className="overview__data">
                {rawData?.patient?.first_name
                    ? rawData?.patient?.first_name
                    : "" + " " + rawData?.patient?.last_name
                    ? rawData?.patient?.last_name
                    : ""}
            </div>
        );
    };

    const deviceSerialBodyTemplate = (rawData: DeviceModel) => {
        return <div className="overview__data">{rawData.serial}</div>;
    };

    const roomNumberBodyTemplate = (rawData: DeviceModel) => {
        return (
            <div className="overview__data">
                {rawData?.patient?.room_number}
            </div>
        );
    };

    const createAlertColumn = () => {
        return <div className="overview__alert-column" />;
    };

    const runningActivityBodyTemplate = (rawData: DeviceModel) => {
        return (
            <div className="overview__data overview__data--centered">
                <CommunicationIcon
                    communicatoinType={
                        rawData?.shadow?.state?.active_communication_info?.id_type ==
                        ActiveCommunicationType.STOP_COMMUNICATION
                            ? ActiveCommunicationType.NONE
                            : rawData?.shadow?.state?.active_communication_info?.id_type
                    }
                    isWrapped={false}
                />
            </div>
        );
    };

    const deviceStatusBodyTemplate = (rawData: DeviceModel) => {
        const deviceState = rawData?.shadow?.state?.presence?.state;
        
        const technicalErrorIcon = rawData?.shadow?.state?.is_healthy === false &&
        deviceState === DeviceConnectionStatus.CONNECTED ? (
            <TechnicalErrorIcon height={"2rem"} width={"2rem"} />
        ) : (
            ""
        );

        const powerIcon =
            !rawData?.shadow?.state?.pwr &&
            deviceState === DeviceConnectionStatus.CONNECTED ? (
                <PowerNotConnectedIcon height={"2rem"} width={"2rem"} />
            ) : (
                ""
            );

        const oopIcon =
            deviceState === DeviceConnectionStatus.CONNECTED &&
            rawData?.shadow?.state?.oop &&
            rawData?.shadow?.settings?.oop_dashboard_indication !== false ? (
                <OOPIcon height={"2rem"} width={"2rem"} />
            ) : (
                <div />
            );
        const wifiStrengthIcon = (
            <WifiStrengthIcon
                currentStrength={rawData?.wifiStrength?.current}
                connectionState={rawData?.shadow?.state?.presence}
            />
        );

        return (
            <div className="overview__icons-wrapper">
                <div className="overview__icons">
                    {technicalErrorIcon}
                    {oopIcon}
                    {powerIcon}
                    {wifiStrengthIcon}
                </div>
            </div>
        );
    };

    const getRowClass = (rawData: DeviceModel) => {
        return {
            "overview__table-alert": isAlert(rawData),
            "overview__table-row": true,
        };
    };

    const openAddPatientDialog = () => {
        const args: OpenDialogArgs = {
            dialogType: DialogTypes.FORM_DIALOG,
            title: t("Add new patient"),
            component: <UpsertPatientForm type={PatientActions.CREATE} onClose={hide} />,
            actionText: t("Save"),
            cancelText: t("Cancel"),
            cancelCallback: trackCloseAddPatientModalXButton,
            actionCallback: trackSaveAddPatientModal
        };

        showDialog(args);
        trackOpenAddPatientModal()
    };

    const bodyTemplate = (height: any) => {
        return <Skeleton height={`${height.height}rem`} className="overview__data skeleton" />;
    };

    const skeletonTable = () => {
        return (
            <DataTable value={skeletonItems} className="p-datatable-striped">
                <Column header={t("PATIENT NAME")} style={{ width: "18%" }} body={bodyTemplate} />
                <Column header={t("ROOM")} style={{ width: "18%" }} body={bodyTemplate} />
                <Column header={t("DEVICE SERIAL")} style={{ width: "18%" }} body={bodyTemplate} />
                <Column header={t("ACTIVE COMMUNICATION")} style={{ width: "38%" }} body={bodyTemplate} />
                <Column header="" style={{ width: "8%" }} body={bodyTemplate} />
            </DataTable>
        );
    };

    useEffect(() => {
        if (location.pathname === '/overview') {
            setIsNearBedView(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        if(process.env.REACT_APP_IS_SAML?.toLowerCase() == 'true'){
            let interval = setInterval(() => {

                let user = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID}.LastAuthUser`);

                if (user) {
                    clearInterval(interval);
                    (async () => {
                        await getSamlUser(dispatch, navigate);
                    })();
                }
            }, 1000);
        }
    }, []);

    return (
        <div className="overview">
            <div className="overview__top">
                <h3 className="overview__headline">{t("Overview")}</h3>
                <Button
                    onClick={openAddPatientDialog}
                    className="button button-regular"
                    disabled={!isDevicesObjUpdated}
                >
                    {t("Add Patient")}
                </Button>
            </div>
            {isDevicesObjUpdated ? <DataTable
                rowHover
                className="overview__table"
                value={devices}
                rowClassName={getRowClass}
                responsiveLayout="scroll"
                onRowClick={navigateToPatientView}
                emptyMessage={t("No patients found")}
            >
                <Column className="overview__alert-wrapper-column" body={createAlertColumn} />
                <Column header={t("PATIENT NAME")} body={patientNameBodyTemplate} />
                <Column header={t("ROOM")} body={roomNumberBodyTemplate} />
                <Column header={t("DEVICE SERIAL")} body={deviceSerialBodyTemplate} />
                <Column header={t("ACTIVE COMMUNICATION")} body={runningActivityBodyTemplate} />
                <Column header="" body={deviceStatusBodyTemplate} />
            </DataTable> : skeletonTable()}
        </div>
    );
};

export const OverviewPage = connect(mapStateToProps, null)(_OverviewTable);
