import React from "react";

export const ResponsesPatientIcon = ({
    height = "24px",
    width = "24px",
    color = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.53125 26.875C7.22781 26.0949 10.4901 22.5049 11.4462 22.5049H21.5545C22.9398 22.5049 25.7679 25.4807 26.4688 26.5297M31 16C31 24.0081 24.5081 30.5 16.5 30.5C8.49187 30.5 2 24.0081 2 16C2 7.99187 8.49187 1.5 16.5 1.5C24.5081 1.5 31 7.99187 31 16ZM21.6941 11.0579C21.6941 8.29151 19.3588 6.03125 16.5004 6.03125C13.6422 6.03125 11.3068 8.29151 11.3068 11.0579C11.3068 13.8242 13.6422 16.0845 16.5004 16.0845C19.3587 16.0845 21.6941 13.8242 21.6941 11.0579Z" stroke="#0F284C" strokeWidth="2.5"/>
    </svg>


);
