import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "src/utils/generalMethods";
import { Avatar } from "primereact/avatar";
import { DeviceModel } from "../../models/device.model";
import { LockIcon } from "../../icons/Lock";
import { LanguageIcon } from "../../icons/Language";
import { EditButtonIcon } from "src/icons/EditButton";
import { DialogTypes, OpenDialogArgs } from "../general/CustomDialog";
import { UpsertPatientForm } from "../forms/UpsertPatientForm";
import { PatientActions } from "src/constans/patientActions";
import { DialogContext } from "src/contexts/DialogContext";
import { useContext } from "react";
import { DialogPropTypes } from "src/contexts/types";
import { useMixpanel } from "src/hooks/useMixpanel";
import { useSelector } from "react-redux";
import { StoreState } from "src/utils/testStore";

interface UserProfileProps {
    profile: string | undefined;
    currentDevice: DeviceModel;
    language: string;
}

export const UserProfile = ({
    profile,
    currentDevice,
    language
}: UserProfileProps): JSX.Element => {
    const { t } = useTranslation();
    const { isSmallTablet } = useSelector((state: StoreState) => ({
        isSmallTablet: state.icus[0].feature_flags.isSmallTablet
    }));
    const { showDialog, hide } = useContext(DialogContext) as DialogPropTypes;
    const { trackCloseEditPatientDetailsModalXButton, trackOpenEditPatientDetailsModal } = useMixpanel()

    const editPatientsDetails = () => {
        if (!currentDevice) {
            console.log("Device id is undefined");
            return;
        }

        const args: OpenDialogArgs = {
            dialogType: DialogTypes.FORM_DIALOG,
            title: t("Edit Patient Details"),
            component: <UpsertPatientForm type={PatientActions.UPDATE} device={currentDevice} onClose={hide} />,
            actionText: t("Save"),
            cancelText: t("Cancel"),
            cancelCallback: trackCloseEditPatientDetailsModalXButton
        };

        trackOpenEditPatientDetailsModal()
        showDialog(args);
    };
    
    return (
        <div className="near-bed-view-page__profile-wrapper">
            <div className="near-bed-view-page__profile-info">
                <Avatar {...(profile === undefined ? { icon: "pi pi-user" } : { image: profile })} className="near-bed-view-page__profile-image p-avatar-circle" shape="circle" />
                <div className="near-bed-view-page__profile-section">
                    {isSmallTablet 
                    ?
                        <div className="near-bed-view-page__profile-info-wrapper">
                            <div className="near-bed-view-page__full-name">
                                {currentDevice?.patient?.first_name} {currentDevice?.patient?.last_name} 
                            </div>
                            <div className="near-bed-view-page__edit-button" onClick={() =>  editPatientsDetails()}>
                                <EditButtonIcon />
                            </div>
                        </div>
                    :
                        <>
                            <div className="near-bed-view-page__full-name">
                                {currentDevice?.patient?.first_name} {currentDevice?.patient?.last_name} 
                            </div>
                            <div className="near-bed-view-page__profile-info-details">
                                <div className="near-bed-view-page__passkey-details">
                                    <div className="near-bed-view-page__details-icon">
                                        <LanguageIcon />
                                    </div>
                                    {capitalizeFirstLetter(language)}
                                </div>
                            </div>
                        </>
                    }                    
                </div>
            </div>
            <div className="near-bed-view-page__profile-details">
            
            </div>
        </div>
    );
};
