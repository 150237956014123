import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { StoreState } from "src/reducers";
import { DialogContext } from "src/contexts/DialogContext";
import { Navbar } from "src/components/general/Navbar";
import { Main } from "src/components/general/Main";
import { CustomDialog, DialogTypes, OpenDialogArgs } from "src/components/general/CustomDialog";
import { DeviceList } from "src/components/general/DeviceList";
import DeviceSettings from "src/components/general/DeviceSettings";
import IcuSettings from "src/components/general/IcuSettings";
import { SettingsPage } from "src/components/pages/SettingsPage";
import { OverviewPage } from "src/components/pages/OverviewPage";
import { PatientViewPage } from "src/components/pages/PatientViewPage";
import { DevicesSettings } from "src/components/pages/settings/DevicesSettings";
import { NotFoundPage } from "src/components/pages/NotFoundPage";
import { NearBedViewPage } from "src/components/pages/NearBedViewPage";
import { Login } from "src/components/authentication/Login";
import { PatientInformationScreen } from "src/components/patientView/PatientInformationScreen";
import { AuthorizationPageWrapper } from "src/components/authentication/AuthorizationPageWrapper";
import "src/scss/components/App.scss";
import { IcuModel, IcuSettingsModel } from "src/models/icu.model";
import { isEnableView } from "src/utils/generalMethods";
import { ViewTypes } from "src/models/Authorization.model";
import { DeviceActionsContext } from "src/contexts/DeviceActionsContext";

interface AppProps {
    isAuthenticated?: boolean;
    views?: ViewTypes[];
    icus?: IcuModel[];
}

const mapStateToProps = ({ authInfo, icus }: StoreState) => {
    return {
        isAuthenticated: !!authInfo?.currentUser?.token,
        views: authInfo?.authorization?.views,
        icus: icus
    };
};

const _App = ({ isAuthenticated, icus, views }: AppProps): JSX.Element => {
    const dialogRef = useRef<any>(null);
    const attentionDialogRef = useRef<any>(null);
    const sleepDialogRef = useRef<any>(null);
    const [isNurseView, setIsNurseView] = useState(isEnableView(ViewTypes.NURSE, views));
    const [isSleepDialogVisible, setIsSleepDialogVisible] = useState(false);
    const [isSleepTime, setIsSleepTime] = useState(false);
    const [isNearBedView, setIsNearBedView] = useState(false);
    const [detachedDeviceId, setDetachedDeviceId] = useState<string | undefined>(undefined);
    
    const getSleepItems = (settings: IcuSettingsModel) => {
        const now = new Date();
        const currentHour = now.getHours();
        const currentMinutes = now.getMinutes();
        const currentTime = currentHour * 60 + currentMinutes;       
        const [sleepStartHour, sleepStartMinutes] = settings?.sleepStart?.split(':').map(Number) || [0, 0];
        const [sleepEndHour, sleepEndMinutes] = settings?.sleepEnd?.split(':').map(Number) || [0, 0];
        const startTimePM = sleepStartHour * 60 + sleepStartMinutes;
        const endTime = sleepEndHour * 60 + sleepEndMinutes;
        
        const updatedSleepTime = (startTimePM > endTime && 
            (currentTime >= startTimePM || currentTime < endTime))
            || (startTimePM < endTime && currentTime >= startTimePM && currentTime < endTime);
        
        return { 
            isSleepTime: updatedSleepTime, 
            currentTime, 
            startTimePM, 
            endTime 
        };
    };

    const checkAndOpenSleepDialog = () => {
        if (!isNearBedView || !isNurseView || !icus?.[0]?.settings?.sleepStart || !icus?.[0]?.settings?.sleepEnd) {
            setIsSleepDialogVisible(false);
            return;
        }

        const { isSleepTime, currentTime, startTimePM, endTime } = getSleepItems(icus[0].settings);
        
        setIsSleepDialogVisible(isSleepTime);
        setIsSleepTime(isSleepTime);
        updateSleepDialogVisibility(currentTime, startTimePM, endTime);
    };

    const updateSleepDialogVisibility = (currentTime: number, startTimePM: number, endTime: number) => {
        if (startTimePM > endTime) {
            if(currentTime > startTimePM && currentTime > endTime) {
                console.log("1:", (24 * 60 - currentTime + endTime) * 60000);
                setTimeout(checkAndOpenSleepDialog, (24 * 60 - currentTime + endTime) * 60000);
            } else if (currentTime < startTimePM && currentTime < endTime) {
                console.log("2:", (endTime - currentTime) * 60000);
                setTimeout(checkAndOpenSleepDialog, (endTime - currentTime) * 60000);
            } else {
                setIsSleepDialogVisible(false);
                console.log("3:", (startTimePM - currentTime) * 60000);
                setTimeout(checkAndOpenSleepDialog, (startTimePM - currentTime) * 60000);
            }
        } else {
            if (currentTime >= startTimePM && currentTime < endTime) {
                console.log("4:", (endTime - currentTime) * 60000);
                setTimeout(checkAndOpenSleepDialog, (endTime - currentTime) * 60000);
            } else {
                if(currentTime < startTimePM) {
                    console.log("5:", (startTimePM - currentTime) * 60000);
                    setTimeout(checkAndOpenSleepDialog, (startTimePM - currentTime) * 60000);
                } else {
                    console.log("6:", (24 * 60 - currentTime + startTimePM) * 60000);
                    console.log({currentTime, startTimePM, endTime});
                    
                    setTimeout(checkAndOpenSleepDialog, (24 * 60 - currentTime + startTimePM) * 60000);
                }
                setIsSleepDialogVisible(false);
            }
        }
    }

    useEffect(() => {
        if (icus?.length) {
            checkAndOpenSleepDialog();
        }
    }, [icus?.length, isNearBedView]);

    useEffect(() => {
        if (isSleepDialogVisible) {
            sleepDialogRef.current?.showDialog({
                dialogType: DialogTypes.SLEEP_DIALOG,
            });
        } else if (sleepDialogRef.current) {
            sleepDialogRef.current?.hide();
        }
    }, [isSleepDialogVisible]);

    return (
        <div className="container">
            <DialogContext.Provider
                value={{
                    showDialog: (args: OpenDialogArgs) => {
                        dialogRef?.current?.showDialog(args);
                    },
                    hide: () => dialogRef?.current?.hide(),
                    resetTimeOut: () => dialogRef?.current?.resetTimeOut(),
                    updateArguments: (args) => dialogRef?.current?.updateArguments(args),
                    showAttentionDialog: (args: OpenDialogArgs) => {
                        attentionDialogRef?.current?.showDialog(args);
                    },
                    hideAttentionDialog: () => attentionDialogRef?.current?.hide(),
                    resetAttentionTimeOut: () => attentionDialogRef?.current?.resetTimeOut(),
                    updateAttentionArguments: (args) => attentionDialogRef?.current?.updateArguments(args)
                }}
            >
                <DeviceActionsContext.Provider value={{
                    detachedDeviceId,
                    setDetachedDeviceId
                }}>
                    <BrowserRouter>
                        {isAuthenticated && <Navbar />}
                        <Main>
                            <Routes>
                                <Route index element={<Navigate to={isAuthenticated ? "/overview" : "/login"} replace />}/>
                                <Route path="/login" element={<Login />} />
                                <Route path="/" element={isAuthenticated ? <Outlet /> : <Navigate to="/login" />}>
                                    <Route path="overview" element={<OverviewPage setIsNearBedView={setIsNearBedView} />} />
                                    <Route
                                        path="patientview"
                                        element={
                                            <AuthorizationPageWrapper pageName="PatientView">
                                                <PatientViewPage />
                                            </AuthorizationPageWrapper>
                                        }
                                    >
                                        <Route path=":deviceId" element={<PatientInformationScreen />} />
                                    </Route>
                                    <Route
                                        path="neartobedpatientview"
                                        element={
                                            <AuthorizationPageWrapper pageName="NearToBedPatientView">
                                                <NearBedViewPage setIsNearBedView={setIsNearBedView} />
                                            </AuthorizationPageWrapper>
                                        }
                                    >
                                        <Route path=":deviceId" element={<PatientInformationScreen />} />
                                    </Route>
                                    <Route path="settings" element={<SettingsPage />}>
                                        <Route index element={<Navigate replace to="icu" />} />
                                        <Route path="icu" element={<IcuSettings />} />
                                        <Route path="devices" element={<DevicesSettings/>}>
                                            <Route path="" element={<DeviceList />} />
                                            <Route path=":deviceId" element={<DeviceSettings />} />
                                        </Route>
                                        <Route path="*" element={<NotFoundPage />} />
                                    </Route>
                                </Route>
                                <Route path="*" element={<NotFoundPage />} />
                            </Routes>
                        </Main>
                        <CustomDialog ref={dialogRef} isSleepTime={isSleepTime}/>
                        <CustomDialog ref={attentionDialogRef} isSleepTime={isSleepTime}/>
                        <CustomDialog ref={sleepDialogRef} isSleepTime={isSleepTime}/>
                    </BrowserRouter>
                </DeviceActionsContext.Provider>
            </DialogContext.Provider>
        </div>
    );
};

export const App = connect(mapStateToProps, null)(_App);