import React, {
    ForwardRefExoticComponent, RefAttributes, RefObject, useCallback, useImperativeHandle, useState, useEffect
} from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { ScrollPanel } from 'primereact/scrollpanel';
import { StoreState } from "src/reducers";
import { AttentionIcon } from "src/icons";
import { isEnableView } from "src/utils/generalMethods";
import { ViewTypes } from "src/models/Authorization.model";
import { ShareLinkDialog } from "./ShareLInkDialog";
import { useMixpanel } from "src/hooks/useMixpanel";
import SleepDialog from "./SleepDialog";
import { IcuModel } from "src/models/icu.model";

export enum DialogTypes {
    ACTION_DIALOG = "ACTION_DIALOG",
    FORM_DIALOG = "FORM_DIALOG",
    ATTENTION_DIALOG = "ATTENTION_DIALOG",
    DAILY_SCHEDULER = "DAILY_SCHEDULER",
    RESPONSES = "RESPONSES",
    LINK_DIALOG = "LINK_DIALOG",
    SLEEP_DIALOG = "SLEEP_DIALOG"
}

export type RefType = RefObject<ForwardRefExoticComponent<RefAttributes<HTMLButtonElement>>>;

export type OpenDialogArgs = {
    dialogType: DialogTypes;
    title?: string;
    isClosable?: boolean;
    component: React.ReactNode;
    actionCallback?: () => void;
    cancelCallback?: () => void;
    actionText?: string;
    cancelText?: string;
    shouldNotHide?: boolean;
};

export type UpdateDialogArgs = Partial<OpenDialogArgs>

const {ACTION_DIALOG, ATTENTION_DIALOG, FORM_DIALOG, RESPONSES, DAILY_SCHEDULER, LINK_DIALOG, SLEEP_DIALOG} = DialogTypes;

interface CustomDialogSelectorResult {
    reopenTime: number;
    isNurseView: boolean;
    icus: IcuModel[];
}

const CustomDialogSelector = ({icus, authInfo}: StoreState): CustomDialogSelectorResult => ({
    reopenTime: icus[0]?.settings?.dialog_reopen_time || 300000,
    isNurseView: isEnableView(ViewTypes.NURSE, authInfo?.authorization?.views),
    icus
})

export const CustomDialog = React.forwardRef<RefType, {isSleepTime: boolean}>(({isSleepTime}, ref) => {
    const {t} = useTranslation();
    const {reopenTime, isNurseView, icus} = useSelector(CustomDialogSelector, shallowEqual);
    const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
    const [title, setTitle] = useState("");
    const [dialogType, setDialogType] = useState<DialogTypes>(ACTION_DIALOG);
    const [component, setComponent] = useState<React.ReactNode>(null);
    const [attentionComponent, setAttentionComponent] = useState<React.ReactNode>(null);
    const [actionCallback, setActionCallback] = useState<Function>(() => {});
    const [cancelCallback, setCancelCallback] = useState<Function>(() => {});
    const [shouldNotHide, setShouldNotHide] = useState<boolean>(false);
    const [actionText, setActionText] = useState("");
    const [cancelText, setCancelText] = useState("");
    const [timeInstance, setTimeInstance] = useState<NodeJS.Timeout>();
    const [submiting, setSubmiting] = useState<boolean>(false);
    const [isClosable, setIsClosable] = useState<boolean>(true);
    const { trackCloseGetLinkWithoutSavingAddPhoneStep,
            trackCloseGetLinkWithoutSavingValidatePhoneStep
        } = useMixpanel();

    const additionalClass = isNurseView ? "nurse-view" : "";

    const onHide = () => {
        setSubmiting(false);
        setIsDialogVisible(false);
        if (attentionComponent && dialogType !== ATTENTION_DIALOG) {
            setComponent(attentionComponent);
        }
    };

    const showDialog = (args: OpenDialogArgs) => {
        setDialogType(args.dialogType);
        if (args.title) setTitle(args.title);
        setComponent(args.component);
        if (args.actionCallback) setActionCallback(() => args.actionCallback);
        if (args.cancelCallback) setCancelCallback(() => args.cancelCallback);
        if (args.actionText) setActionText(args.actionText);
        if (args.cancelText) setCancelText(args.cancelText);
        setShouldNotHide(args.shouldNotHide || false);
        setIsDialogVisible(true);
    };

    const onAction = () => {
        setSubmiting(true);
        if (actionCallback) actionCallback();
        if (!shouldNotHide) onHide();
    };

    const resetTimeOut = () => {
        clearTimeout(timeInstance);
    }

    useImperativeHandle(ref, (): any => ({
        showDialog(args: OpenDialogArgs) {
            showDialog(args)
        },
        hide() {
            onHide();
        },
        resetTimeOut() {
            if (isDialogVisible) {
                onHide();
            }
            resetTimeOut();
        },
        updateArguments(args: OpenDialogArgs) {
            if (args.title) setTitle(args.title);
            if (args.isClosable !== undefined) setIsClosable(args.isClosable);
        }
    }));

    const buildDialog = (dialogType: DialogTypes) => {
        switch (dialogType) {
            case ACTION_DIALOG:
                return buildActionDialog();
            case FORM_DIALOG:
                return buildFormDialog();
            case ATTENTION_DIALOG:
                return buildAttentionDialog();
            case DAILY_SCHEDULER:
                return buildDailySchedulerDialog();
            case RESPONSES:
                return buildResponsesDialog();
            case LINK_DIALOG:
                return buildLinkDialog();
            case DialogTypes.SLEEP_DIALOG:
                return buildSleepDialog();
            default:
                return buildActionDialog();
        }
    };

    const cancelFunc = useCallback(() => {
        if (cancelCallback) cancelCallback();
        onHide();
    }, [cancelCallback])

    const buildActionDialog = () => {
        const renderActionFooter = () => {
            return (
                <div className="custom-dialog__action-footer">
                    <Button
                        label={cancelText}
                        onClick={cancelFunc}
                        className={`custom-dialog__action-button custom-dialog__cancel-button
                                ${submiting ? "button-disabled" : ""}
                            `}
                        disabled={submiting}
                    />
                    <Button
                        label={`${submiting ? "" : actionText}`}
                        onClick={onAction}
                        className={`custom-dialog__action-button
                                ${submiting ? "button-disabled" : ""}
                            `}
                        disabled={submiting}
                    >
                        {submiting ? (
                            <div className="button-loading">
                                <ProgressSpinner/>
                            </div>
                        ) : null}
                    </Button>
                </div>
            );
        };

        return (
            <Dialog
                dismissableMask
                draggable={false}
                header={title}
                visible={isDialogVisible}
                footer={renderActionFooter()}
                onHide={cancelFunc}
                className={`custom-dialog ${additionalClass}`}
                headerClassName="custom-dialog__header"
                contentClassName="custom-dialog__content"
            >
                {component}
            </Dialog>
        );
    };

    const buildAttentionDialog = () => {
        if (!attentionComponent) {
            setAttentionComponent(component);
        }

        const handleHide = () => {
            cancelFunc();
            // TODO: add dinamic value
            const timeoutId = setTimeout(function () {
                setDialogType(ATTENTION_DIALOG);
                setIsDialogVisible(true);
            }, reopenTime);
            setTimeInstance(timeoutId);
        }

        return (
            <Dialog
                header={
                    <div className="attention-content__title">
                        <AttentionIcon/>
                        <div className="attention-content__title-text">
                            {t("Attention")}
                        </div>
                    </div>
                }
                dismissableMask
                visible={isDialogVisible && !isSleepTime}
                onHide={handleHide}
                className={`custom-dialog attention-content__dialog ${additionalClass}`}
                headerClassName="attention-content__header"
                contentClassName="custom-dialog__content"
            >
                <div className="attention-content__content-wrapper">
                    {component}
                </div>
            </Dialog>
        );
    };

    const buildDailySchedulerDialog = () => {
        return (
            <Dialog
                header={
                    <div className="daily-scheduled-content__title">
                        <div className="daily-scheduled-content__title-text">
                            {t("Daily Schedule")}
                        </div>
                    </div>
                }
                visible={isDialogVisible}
                onHide={cancelFunc}
                className={`custom-dialog daily-scheduler-dialog daily-scheduled-content__dialog ${additionalClass}`}
                headerClassName="daily-scheduled-content__header"
                contentClassName="custom-dialog__content"
                draggable={false}
                dismissableMask
                position="center"
            >
                <ScrollPanel className="daily-scheduled-content__scroll-panel">
                    <div className="daily-scheduled-content__content-wrapper">
                        {component}
                    </div>
                </ScrollPanel>
            </Dialog>
        );
    };

    const buildResponsesDialog = () => {
        return (
            <Dialog
                header={
                    <div className="custom-dialog__title">
                        <div className="custom-dialog__title-text">
                            {t("Patient's responses")}
                        </div>
                    </div>
                }
                dismissableMask
                draggable={false}
                visible={isDialogVisible}
                onHide={cancelFunc}
                className={`custom-dialog pateint-response-dialog ${additionalClass}`}
                headerClassName="responses-content__header"
                contentClassName="custom-dialog__content"
            >
                <ScrollPanel className="custom-dialog__scroll-panel">
                    <div className="custom-dialog__content-wrapper">
                        {component}
                    </div>
                </ScrollPanel>
            </Dialog>
        );
    };

    const buildFormDialog = () => {
        return (
            <Dialog
                dismissableMask={isClosable}
                header={title}
                visible={isDialogVisible}
                onHide={cancelFunc}
                className={`custom-dialog ${additionalClass}`}
                headerClassName="custom-dialog__header"
                contentClassName="custom-dialog__content"
                draggable={false}
                closable={isClosable}
            >
                {component}
            </Dialog>
        );
    };

    const buildLinkDialog = () => {
        return (
            <Dialog
                header={
                    <div className="custom-dialog__title">
                        <div className="custom-dialog__title-text">
                        </div>
                    </div>
                }
                visible={isDialogVisible}
                onHide={onHide}
                className={`custom-dialog link-dialog link-dialog--input ${additionalClass}`}
                headerClassName="link-dialog__header"
                contentClassName="link-dialog__content"
                draggable={false}
                dismissableMask
                closable={true}
            >
                <ShareLinkDialog onClose={onHide} isNurseView={isNurseView} />
            </Dialog>
        );
    };

    const checkSleepTime = () => {
        console.log("Checking sleep time...");
        if (!isNurseView) return false;
        
        const currentPath = window.location.pathname;
        if (!currentPath.includes('neartobedpatientview')) return false;

        const settings = icus[0]?.settings;
        if (!settings?.sleepStart || !settings?.sleepEnd) {
            console.log("No sleep settings found");
            return false;
        }

        const now = new Date();
        const currentHour = now.getHours();
        const currentMinutes = now.getMinutes();
        const currentTime = currentHour * 60 + currentMinutes;

        const [sleepStartHour, sleepStartMinutes] = settings.sleepStart.split(':').map(Number);
        const [sleepEndHour, sleepEndMinutes] = settings.sleepEnd.split(':').map(Number);
        const startTimePM = sleepStartHour * 60 + sleepStartMinutes;
        const endTime = sleepEndHour * 60 + sleepEndMinutes;

        const isSleepTime = (startTimePM > endTime && 
            (currentTime >= startTimePM || currentTime < endTime))
            || (startTimePM < endTime && currentTime >= startTimePM && currentTime < endTime);

        return isSleepTime;
    };

    const handleSleepHide = () => {
        // Clear any existing timeout
        if (timeInstance) {
            clearTimeout(timeInstance);
        }

        const settings = icus[0]?.settings;

        onHide();
        
        // Set new timeout to check and potentially show dialog again
        const newTimeInstance = setTimeout(() => {
            if (checkSleepTime()) {
                setIsDialogVisible(true);
            }
        }, settings.dialog_reopen_time);
        
        setTimeInstance(newTimeInstance);
    };

    // Add cleanup
    useEffect(() => {
        return () => {
            if (timeInstance) {
                clearTimeout(timeInstance);
            }
        };
    }, [timeInstance]);

    const buildSleepDialog = () => {
        return (
            <Dialog
            header={
                <div className="sleep-dialog__title">
                </div>
            }
            visible={isDialogVisible && isSleepTime}
            onHide={handleSleepHide}
            className={`custom-dialog sleep-dialog ${additionalClass}`}
            headerClassName="sleep-dialog__header"
            contentClassName="sleep-dialog__content"
            draggable={false}
            dismissableMask
            closable={true}
        >
            <SleepDialog 
                isNurseView={isNurseView}
                visible={isDialogVisible}
                onHide={() => setIsDialogVisible(false)}
            />
        </Dialog>
        );
    };

    return buildDialog(dialogType);
});
