import React from "react";

export const NoPatientIcon = ({
    height = "15",
    width = "7",
    stroke = "#0F284C",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg width="147" height="163" viewBox="0 0 153 169" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M144.931 120.157C144.931 134.223 133.584 145.625 119.587 145.625C105.59 145.625 94.2426 134.223 94.2426 120.157C94.2426 106.091 105.59 94.6877 119.587 94.6877C133.584 94.6877 144.931 106.091 144.931 120.157Z" fill="#7FD9E5"/>
        <path d="M73.964 166H23.2753C12.0775 166 2.99993 156.878 3 145.625L3.00078 23.3749C3.00086 12.1222 12.0785 3 23.2762 3H114.518C125.716 3 134.793 12.1222 134.793 23.3751V64.1252M139.862 140.532L150 150.719M144.931 120.157C144.931 134.223 133.584 145.625 119.587 145.625C105.59 145.625 94.2426 134.223 94.2426 120.157C94.2426 106.091 105.59 94.6877 119.587 94.6877C133.584 94.6877 144.931 106.091 144.931 120.157Z" stroke="#0F284C" strokeWidth="5.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M48 68C51.4791 62.1047 59.104 58.0698 71 58.0698C82.896 58.0698 90.5209 62.1047 94 68M80.4496 37.6C80.4496 42.9019 76.2189 47.2 71 47.2C65.7811 47.2 61.5504 42.9019 61.5504 37.6C61.5504 32.2981 65.7811 28 71 28C76.2189 28 80.4496 32.2981 80.4496 37.6Z" stroke="#0F284C" strokeWidth="5.5" strokeLinecap="round"/>
    </svg>
);
