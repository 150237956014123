import React, { useEffect, useRef, useState } from "react";
import {
    GeneralRecordingModel,
    NOT_PLAYING,
    PreCommunicationSlotModel,
    RecordingSlotsModel,
} from "../../models/recording.model";
import { useTranslation } from "react-i18next";
import { TOAST_SEVERITY } from "../../models/toast.model";
import { Button } from "primereact/button";
import { SquareIcon, PlayIcon } from "../../icons";
import { playRecordings, togglePlaying } from "../../utils/playerMethods";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { SLOT_TYPES } from "../forms/ScheduleEventForm/constants";

interface RecordingSlotsProps {
    recordings: GeneralRecordingModel[];
    slots: RecordingSlotsModel[] | PreCommunicationSlotModel[];
    patientId: string;
    showToast: Function;
    updateRecordingSlot: Function;
    deleteRecordingSlot: Function;
}

const RecordingSlots: React.FC<RecordingSlotsProps> = ({
    recordings,
    slots,
    updateRecordingSlot,
    deleteRecordingSlot,
    showToast,
    patientId
}: RecordingSlotsProps) => {
    const { t } = useTranslation();

    const [playingArray, setPlayingArray] = useState<boolean[]>([]);
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
    
    const [playingIndex, setPlayingIndex] = useState<number>(NOT_PLAYING);

    const slotDropdownRefArray = useRef<React.RefObject<any>[]>([]);

    useEffect(() => {
        setPlayingArray(Array(slots.length).fill(false));
    }, []);

    useEffect(() => {
        return () => {
            if (audio) {
                audio?.pause();
                audio.src = "";
                setAudio(null);
            }
        };
    }, [recordings, audio]);

    const onSlotValueChange = (
        value: string | undefined,
        prevId: string,
        slotKey: string | number
    ) => {
        updateRecordingSlot(
            patientId,
            slotKey,
            value,
            prevId,
            (result: boolean) => {
                if (result) {
                    showToast({
                        severity: TOAST_SEVERITY.SUCCESS,
                        summary: t("Success"),
                        detail: t(
                            `Recording was assigned successfully to message slot ${Number(slotKey) > 0 ? slotKey : SLOT_TYPES[slotKey]}`
                        ),
                    });
                } else {
                    showToast({
                        severity: TOAST_SEVERITY.ERROR,
                        summary: t("Something went wrong!"),
                        detail: t(
                            `Unable to assign recording to message slot ${Number(slotKey) > 0 ? slotKey : SLOT_TYPES[slotKey]}`
                        ),
                    });
                }
            }
        );
    };


    const onSlotDelete = (
        prevId: string,
        slotKey: string | number,
        slotIndex: number
    ) => {

        (slotDropdownRefArray?.current[slotIndex] as any).hide();

        deleteRecordingSlot(
            patientId,
            slotKey,
            prevId,
            (result: boolean) => {
                if (result) {
                    showToast({
                        severity: TOAST_SEVERITY.SUCCESS,
                        summary: t("Success"),
                        detail: t(
                            `Slot ${Number(slotKey) > 0 ? slotKey : SLOT_TYPES[slotKey]} was cleared`
                        ),
                    });
                } else {
                    showToast({
                        severity: TOAST_SEVERITY.ERROR,
                        summary: t("Something went wrong!"),
                        detail: t(
                            `Unable to clear slot ${Number(slotKey) > 0 ? slotKey : SLOT_TYPES[slotKey]}`
                        ),
                    });
                }
            }
        );
    };

    function getKey(slot: RecordingSlotsModel | PreCommunicationSlotModel) {
        return (slot as RecordingSlotsModel)?.number || (slot as PreCommunicationSlotModel)?.name;
    }

    return (
        <div className="recording-slots">
            <ul className="recording-slots__list">
                {slots.map((slot, index) => {                  
                    let key: string | number = getKey(slot)

                    return (
                        <li
                            className={"recording-slots__item"}
                            key={key}
                        >
                            <div className="recording-slots__item--start">
                                <div className="recordings-list__play">
                                    <Button
                                        rounded
                                        onClick={() =>
                                            togglePlaying(
                                                recordings.findIndex(recording => recording.id == slot.recording_id),
                                                setPlayingArray,
                                                audio,
                                                playRecordings,
                                                recordings,
                                                setAudio,
                                                setPlayingIndex,
                                                togglePlaying,
                                                index
                                            )
                                        }
                                        className="recording-slots__play-button recording-general__play-button button"
                                        disabled={playingArray.some(
                                            (p, i) => p && i !== index
                                        ) || !slot.recording_id}
                                        icon={
                                            playingArray[index] ? (
                                                <SquareIcon />
                                            ) : (
                                                <PlayIcon />
                                            )
                                        }
                                    ></Button>
                                </div>
                                <div className="recording-slots__name">
                                    {!Number.isInteger(key) || key as number > 0 ? 
                                        `${t("Slot")} ${key}` 
                                    : 
                                        `${slot.type} Slot`
                                    }
                                </div>
                                <div className="recording-slots__dropdown med-dropdown">
                                    <Dropdown
                                        key={index}
                                        ref={(ref: any) => (slotDropdownRefArray.current[index] = ref)}
                                        optionLabel="name"
                                        optionValue="id"
                                        className="med-dropdown__dropdown"
                                        options={recordings}
                                        onChange={(event) => {onSlotValueChange(event?.value, slot.recording_id, key)}}
                                        value={slot.recording_id}
                                        placeholder={t("Select a slot")}
                                        panelClassName="med-dropdown__dropdown-panel"
                                        disabled={playingArray[index]}
                                        panelFooterTemplate={
                                        <Button
                                            disabled={!slot.recording_id}
                                            onClick={() => onSlotDelete(slot.recording_id, key, index)}
                                            className={`button button-regular button-dropdown ${!slot.recording_id ? "button-dropdown--disabled" : ""}`}
                                            
                                        >
                                            {t("Clear Selection")}
                                        </Button>}
                                    />
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default RecordingSlots;
