export const MoonIcon = ({
    height = "145",
    width = "145",
    color = "#0F284C",
    className = '',
    ...props
  }: React.SVGProps<SVGSVGElement>) => (
    <svg className={className} width={width} height={height} viewBox="0 0 145 145" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M138.727 93.4343C132.301 95.383 125.484 96.4307 118.423 96.4307C79.8398 96.4307 48.5623 65.1526 48.5623 26.5689C48.5623 19.5101 49.6092 12.6958 51.5561 6.27282C22.8759 14.9707 2 41.6165 2 73.1382C2 111.722 33.2775 143 71.8602 143C103.384 143 130.032 122.12 138.727 93.4343Z" fill="#00B4CD" fillOpacity="0.5"/>
        <path d="M121.636 70.3652V49.0011M121.636 49.0011V27.6369M121.636 49.0011L143 49.0011M121.636 49.0011H100.273M87.4545 27.6369V14.8185M87.4545 14.8185V2M87.4545 14.8185L100.273 14.8185M87.4545 14.8185L74.6364 14.8185M138.727 93.4343C132.301 95.383 125.484 96.4307 118.423 96.4307C79.8398 96.4307 48.5623 65.1526 48.5623 26.5689C48.5623 19.5101 49.6092 12.6958 51.5561 6.27282C22.8759 14.9707 2 41.6165 2 73.1382C2 111.722 33.2775 143 71.8602 143C103.384 143 130.032 122.12 138.727 93.4343Z" stroke="#0F284C" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
); 