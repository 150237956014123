import React, { useContext } from "react";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { Action } from "redux";
import { Field, InjectedFormProps, reduxForm, SubmissionError } from "redux-form";
import { ThunkDispatch } from "redux-thunk";
import { DeviceModel } from "../../models/device.model";
import { UpsertPatientDto } from "../../models/form.model";
import { isEnableView, sortAndModifyVoicesToUpperCase } from "src/utils/generalMethods";
import { VoiceModel } from "../../models/voice.model";
import { ToastModel, TOAST_SEVERITY } from "../../models/toast.model";
import { StoreState } from "../../reducers";
import { MedDropDown } from "./customComponents/MedDropDown";
import { MedInput } from "./customComponents/MedInput/MedInput";
import { isInteger } from "./validationMethods";
import { attachPatientToDevice, updatePatientDetails, updateToast } from "../../actions";
import { ProgressSpinner } from "primereact/progressspinner";
import { PatientActions } from "../../constans/patientActions";
import { useLocation, useNavigate } from "react-router-dom";
import { ViewTypes } from "../../models/Authorization.model";
import { useMixpanel } from "src/hooks/useMixpanel";
import { DeviceActionsContext } from "src/contexts/DeviceActionsContext";
import { DialogContext } from "src/contexts/DialogContext";
import { DialogPropTypes } from "src/contexts/types";

interface UpsertPatientFormCustomProps {
    addPatient?: Function;
    voices?: VoiceModel[];
    devices?: DeviceModel[];
    onClose: Function;
    attachPatientToDevice: Function;
    updatePatientDetails: Function;
    device?: DeviceModel| undefined;
    showToast: Function;
    type: PatientActions;
    views: ViewTypes[] | undefined 
    deviceId?: string | undefined;
    setShowDetachedDevice?: (show: boolean) => void;
}

const USED = "&&used";

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, void, Action>, ownProps:any
) => {
    return {
        attachPatientToDevice: (
            patientWithDeviceId: UpsertPatientDto,
            callBack: (result: { status: boolean; error?: any }) => void
        ) => {
            dispatch(attachPatientToDevice(patientWithDeviceId, callBack));
        },
        updatePatientDetails: (
            deviceId: string,
            patientDetails: UpsertPatientDto,
            views: ViewTypes[] | undefined,
            callBack: (result: { status: boolean; error?: any }) => void
        ) => {
            dispatch(updatePatientDetails(ownProps.device?.id, patientDetails, views, callBack));
        },
        showToast: (ToastModel: ToastModel) =>
            dispatch(updateToast(ToastModel)),
    };
};

const mapStateToProps = ({ tenant, icus, deviceObject, authInfo }: StoreState, ownProps: any) => { 
    let initialValues = {
        patient_voice_id: icus[0].settings.patient_voice_id,
    } as any;
    if(ownProps.type === PatientActions.UPDATE) { 
        initialValues = {
            patient_voice_id: ownProps.device?.shadow?.settings?.patient_voice_id,
            first_name: ownProps.device?.patient?.first_name,
            room_number: ownProps.device?.patient?.room_number,
            device_id: ownProps.device?.id
        } 
    } else if (ownProps.deviceId) {
        initialValues.device_id = ownProps.deviceId;
    }

    return {
        initialValues,
        voices: tenant.voices,
        devices: deviceObject.devices,
        device: ownProps.device,
        views: authInfo?.authorization?.views
    };
};

const validate = (values: any, ownProps:any) => {
    const errors: any = {};

    if (!values.first_name) {
        errors.first_name = "Patient Name is a mandatory field";
    } else if (values.first_name?.length >= 50) {
        errors.username = "Must be 50 characters or less";
    }

    if (values.room_number) {
        if (!isInteger(values.room_number)) {
            errors.room_number = "Room number should be a number";
        }
    }

    if (!values.device_id && ownProps.type === PatientActions.CREATE) {
        errors.device_id = "Device is a mandatory field";
    } else if ((values.device_id as string)?.includes(USED)) {
        errors.device_id = "The device is linked to another patient.";
    }

    return errors;
};

export const _UpsertPatientForm = ({
    voices,
    devices,
    device,
    handleSubmit,
    attachPatientToDevice,
    updatePatientDetails,
    showToast,
    submitting,
    onClose,
    type,
    views,
    deviceId,
    setShowDetachedDevice
}: UpsertPatientFormCustomProps &
    InjectedFormProps<{}, UpsertPatientFormCustomProps>): JSX.Element => {
    const { t } = useTranslation();
    const location = useLocation();
    const { hideAttentionDialog } = React.useContext(DialogContext) as DialogPropTypes;

    const isOverviewPage = location.pathname.includes('/overview');
    const { isSmallTablet } = useSelector((state: StoreState) => ({
        isSmallTablet: state.icus[0].feature_flags.isSmallTablet
    }));
    const deviceActionsContext = useContext(DeviceActionsContext);

    const isNurseView = isEnableView(ViewTypes.NURSE, views);
    const {
        trackSaveAddPatientModal, trackSaveEditPatientDetailsModal, trackCloseAddPatientModalCancelButton,
        trackCloseEditPatientDetailsModalCancelButton
    } = useMixpanel()
    const navigate = useNavigate();
    const isCreate = type === PatientActions.CREATE
    
    const addUsedToDevices = (devices: DeviceModel[] | undefined, currentDeviceId: string | undefined) => {
        if (!devices) return;

        return devices.map((device: DeviceModel) => {
            let newDevice:
                | (DeviceModel & { serialWithUsed: string })
                | DeviceModel;
            newDevice = {
                ...device,
                serialWithUsed: `${device.serial}${t(
                    device.patient && currentDeviceId !== device.id ? " (Used)" : ""
                )}`,
                id: device.patient && currentDeviceId !== device.id ? `${device.id}${USED}` : device.id
            };
            return newDevice;
        });
    };

    const submitPromise = (values: any) =>{
        const successTexts: string[] = [];
        successTexts[PatientActions.CREATE] = "The patient was added.";
        successTexts[PatientActions.UPDATE] = "The patient was updated.";
        const failureTexts: string[] = [];
        failureTexts[PatientActions.CREATE] = "The patient wasn't added";
        failureTexts[PatientActions.UPDATE] = "The patient wasn't updated.";

        return new Promise((resolve) => {
            const cb = (result: { status: boolean; error?: string }, page? :string) => {
                try {
                    if (!result.status) {
                        throw new SubmissionError({
                            _error: result.error,
                        });
                    }
                    showToast({
                        severity: TOAST_SEVERITY.SUCCESS,
                        summary: t("Success"),
                        detail: t(successTexts[type]),
                    });
                    deviceActionsContext.setDetachedDeviceId?.(undefined);
                    if(setShowDetachedDevice) {
                        setShowDetachedDevice(false)
                    }
                    resolve(true);
                    if (page) {
                        navigate(page);
                    }
                    onClose();
                } catch (error) {
                    showToast({
                        severity: TOAST_SEVERITY.ERROR,
                        summary: t("Something went wrong!"),
                        detail: t(failureTexts[type]),
                    });
                }
            }
            if (type === PatientActions.CREATE) {
                trackSaveAddPatientModal();
                attachPatientToDevice(values, cb);

                if(!isOverviewPage && isNurseView && isSmallTablet && deviceId !== values.device_id) {
                    hideAttentionDialog()
                    navigate(`/neartobedpatientview/${values.device_id}`);
                }
            } else {
                trackSaveEditPatientDetailsModal();
                updatePatientDetails(devices, values, views, cb);
            }
        });
    }

    const submit = async (values: any) => {
        return await submitPromise(values);
    };

    const handleCancel = (event: React.MouseEvent) => {
        event.preventDefault();
        isCreate ? trackCloseAddPatientModalCancelButton() : trackCloseEditPatientDetailsModalCancelButton()
        onClose();
    }

    return (
        <div className="create-patient">
            <form
                onSubmit={handleSubmit(submit)}
                className="create-patient__field-set form"
            >
                <div className="form__conatainer">
                    <div className="form__row">
                        <div className="form__input">
                            <Field
                                component={MedInput}
                                label={t("Patient Name")}
                                name="first_name"
                                placeholder={t("Enter the patient’s name")}
                                type="text"
                            />
                        </div>
                        <div className="form__input">
                            <Field
                                component={MedInput}
                                label={t("Room Number")}
                                name="room_number"
                                placeholder={t("Enter room number")}
                                type="text"
                                normalize={(value: string) =>
                                    (value && Number(value)) ||
                                    (!Number(value) && "")
                                }
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__dropdown">
                            <Field
                                name="patient_voice_id"
                                label={t("Language")}
                                component={MedDropDown}
                                disabled={false}
                                optionLabel="language.name"
                                optionValue="id"
                                options={sortAndModifyVoicesToUpperCase(voices)}
                                placeholder={t("Select Language")}
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__dropdown">
                            <Field
                                name="device_id"
                                label={t("Device")}
                                component={MedDropDown}
                                disabled={false}
                                optionLabel="serialWithUsed"
                                optionValue="id"
                                options={addUsedToDevices(devices, device?.id)}
                                placeholder={t("Select a device")}
                            />
                        </div>
                    </div>
                    <div className="form__footer">
                        <Button
                            label={t("Cancel")}
                            className="form__submit-button p-button-outlined button-form-cancel button button-form"
                            onClick={handleCancel}
                        />
                          <Button
                            type="submit"
                            className={`form__save-button button button-form ${
                                submitting ? "button-disabled" : ""
                            }`}
                            disabled={submitting}
                        >
                            {submitting ? (
                                <div className="button-loading">
                                    <ProgressSpinner />
                                </div>
                            ) : (
                                t("Save")
                            )}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

const componentWithForm = reduxForm<{}, UpsertPatientFormCustomProps>({
    form: "upsert_patient",
    enableReinitialize: false,
    validate,
})(_UpsertPatientForm);

export const UpsertPatientForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(componentWithForm);
