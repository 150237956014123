import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ZenObservable } from "zen-observable-ts";
import { NavigateFunction, useNavigate, useParams, useLocation } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { connect, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { ProgressSpinner } from "primereact/progressspinner";
import { IcuFeatureFlagsModel } from "src/models/icu.model";
import { RecordingsObjModel } from "src/models/recording.model";
import { VoiceModel } from "src/models/voice.model";
import { NOTIFICATIONS, NotificationModel } from "src/models/notification.model";
import { ToastModel, TOAST_SEVERITY } from "src/models/toast.model";
import {
    ActiveCommunicationType, CommunicationErrorType, DeviceConnectionStatus, DeviceModel, DeviceShadowModel,
    mapCommunicationErrorTypeToString, mapCommunicationTypeToStringPlayButtons
} from "src/models/device.model";
import { fetchFamilyRecordings, updatePatientImage, updateToast, updateQNAStatus, fetchRecordingSlots } from "src/actions";
import { fetchSchedulerEvents } from "src/actions/schedulerEvent.action";
import { StoreState } from "src/reducers";
import {
    CommunicationErrorIcon, PatientIcon, RedDotIcon, EditButtonIcon, CameraIcon, CommunicationIcon, PlayIcon, WrapSize
} from "src/icons";
import { filterDevicesWithPatient, getS3ItemLink, getDeviceConnectionError } from "src/utils/generalMethods";
import communicationApi from 'src/apis/communication';
import { useMixpanel } from "src/hooks/useMixpanel";
import { DialogContext } from "src/contexts/DialogContext";
import { DialogPropTypes } from "src/contexts/types";
import { communicatonType } from "src/constans/communicationEnum";
import { SenderSource } from "src/constans/senderSourceEnum";
import { PatientActions } from "src/constans/patientActions";
import { GenericSubscriptionActions, GenericSubscriptionResources } from "src/constans/genericSubscription.enum";
import AmplifyService from "src/services/amplifyService";
import IotService from "src/services/iot.service";
import NotificationService from "src/services/notification.service";
import { UpsertPatientForm } from "src/components/forms/UpsertPatientForm";
import { DialogTypes, OpenDialogArgs } from "src/components/general/CustomDialog";
import { VideoStreamBox } from "src/components/patientView/VideoStreamBox";
import { PetientActionMenu } from "src/components/patientView/PatientActionMenu";
import { UserProfile } from "src/components/nearBedView/UserProfile";
import { Responses } from "src/components/nearBedView/Responses";
import { NearBedSchedulerView } from "src/components/nearBedView/NearBedSchedulerView/NearBedSchedulerView";
import { CurrentCommunicationView } from "src/components/nearBedView/CurrentCommunicationView";
import {
    getFamilyRecordingsDuration, getRoundedMinutesFromMilliseconds
} from "src/components/nearBedView/NearBedSchedulerView/utils";
import { ShareLink } from "../ShareLink";
import { ResponsesPatientIcon } from "src/icons/ResponsesPatientIcon";
import { DeviceActionsContext, DeviceActionsContextType } from 'src/contexts/DeviceActionsContext';
import NoPatientPage from "../nearBedView/NoPatientPage";

interface NearBedViewPageProps {
    devices?: DeviceModel[];
    featureFlags: IcuFeatureFlagsModel;
    timeZone?: string;
    recordings?: Map<string, RecordingsObjModel>
    voices: VoiceModel[];
    showToast: Function;
    icuId?: string;
    tenantId?: string;
    musicDuration?: string;
    familyDuration?: string;
    relaxingMessageDuration: string;
    fetchSchedulerEvents: Function;
    fetchFamilyRecordings: Function;
    // fetchGeneralRecordings: Function;
    fetchRecordingSlots: Function;
    updatePatientImage: Function;
    updateQNAStatus: Function;
    setIsNearBedView: Function;
}

const mapStateToProps = ({ deviceObject, icus, recordings, tenant }: StoreState) => {
  return {
    devices: filterDevicesWithPatient(deviceObject.devices),
    featureFlags: icus[0]?.feature_flags,
    //TODO: change to set specific icu.
    timeZone: icus[0]?.settings?.account_timezone,
    recordings: recordings,
    voices: tenant.voices,
    icuId: icus[0]?._id,
    tenantId: icus[0]?.tenant_id,
    musicDuration: `${getRoundedMinutesFromMilliseconds(icus[0]?.settings?.stream_timeout)} min`,
    familyDuration: getFamilyRecordingsDuration(icus[0]?.settings?.family_recordings_duration, icus[0]?.settings?.family_recordings_tolerance),
    relaxingMessageDuration: "2 min"
  };
};

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, void, Action>
) => {
    return {
        fetchSchedulerEvents: () => {
            dispatch(fetchSchedulerEvents());
        },
        fetchFamilyRecordings: (patienId: string) => {
            dispatch(fetchFamilyRecordings(patienId));
        },
        // fetchGeneralRecordings: (patientId: string) =>
        //     dispatch(fetchGeneralRecordings(patientId)),
        fetchRecordingSlots: (patientId: string) =>
            dispatch(fetchRecordingSlots(patientId)),
        showToast: (ToastModel: ToastModel) =>
            dispatch(updateToast(ToastModel)),
        updatePatientImage: (deviceId: string, image: string) =>{
            dispatch(updatePatientImage(deviceId, image))
        },
        updateQNAStatus: (device: DeviceModel) =>{
            dispatch(updateQNAStatus(device))
        }
    };
};

const {FAMILY, MUSIC, NONE, STOP_COMMUNICATION, GENERAL_MESSAGE} = ActiveCommunicationType

export const _NearBedViewPage = ({
    devices,
    featureFlags,
    timeZone,
    recordings,
    voices,
    showToast,
    icuId,
    tenantId,
    fetchSchedulerEvents,
    fetchFamilyRecordings,
    musicDuration,
    familyDuration,
    relaxingMessageDuration,
    // fetchGeneralRecordings,
    fetchRecordingSlots,
    updatePatientImage,
    updateQNAStatus,
    setIsNearBedView
}: NearBedViewPageProps): JSX.Element => {
    const { t } = useTranslation();
    const {
        trackSendingMusic, trackSendingFamilyMessage, trackSendingRelaxingMessage, trackCloseEditPatientDetailsModalXButton,
        trackOpenEditPatientDetailsModal, trackCloseDeviceOffAlert, trackCloseWifiDisconnectedAlert,
        trackClosePowerDisconnectedAlert, trackCloseTechnicalIssueAlert, trackOpenResponseModal, trackCloseResponseModal
    } = useMixpanel()
    const navigate: NavigateFunction = useNavigate();
    let params = useParams();
    const [isVideoStreamReady, setIsVideoStreamReady] = useState(false);
    const [isResponsesEmpty, setIsResponsesEmpty] = useState(true);
    const [familyMessageDisabled, setFamilyMessageDisabled] = useState(true);
    const [relaxingMessageDisabled, setRelaxingMessageDisabled] = useState(true);
    const [buttonsDisabled, setButtonsDisabled] = useState(true);
    const [sendingFamilyMessage, setSendingFamilyMessage] = useState(false);
    const [sendingRelaxingMessage, setSendingRelaxingMessage] = useState(false);
    const [sendingPersonalMusic, setSendingPersonalMusic] = useState(false);
    const [isLogsInitiate, setIsLogsInitiate] = useState(false);
    const [lastLogTime, setLastLogTime] = useState<string>("");
    const [currentDevice, setCurrentDevice] = useState<DeviceModel>();
    const [language, setLanguage] = useState<string>("");
    const [timeoutId, setTimeOutId] = useState<NodeJS.Timeout>();
    const [unreadNotification, setUnreadNotification] = useState(false);
    const { showDialog, hide, showAttentionDialog, hideAttentionDialog, resetAttentionTimeOut  } = React.useContext(
        DialogContext
    ) as DialogPropTypes;
    const [currentSchedulerSubscrition, setCurrentSchedulerSubscrition] = useState<ZenObservable.Subscription | null>(null);
    const videoStreamRef = useRef<any>(null);
    const location = useLocation();
    const { isSmallTablet } = useSelector((state: StoreState) => ({
        isSmallTablet: state.icus[0]?.feature_flags?.isSmallTablet || false
    }));
    const {detachedDeviceId} = useContext(DeviceActionsContext);
    const [showDetachedDevice, setShowDetachedDevice] = useState(false);
    
    useEffect(() => {
        if (location.pathname.includes('neartobedpatientview')) {
            setIsNearBedView(true);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (isSmallTablet && detachedDeviceId && params.deviceId === detachedDeviceId) {
            setShowDetachedDevice(true);
        }
    }, [detachedDeviceId]);

    const editPatientsDetails = () => {
        if (!currentDevice) {
            console.log("Device id is undefined");
            return;
        }

        const args: OpenDialogArgs = {
            dialogType: DialogTypes.FORM_DIALOG,
            title: t("Edit Patient Details"),
            component: <UpsertPatientForm type={PatientActions.UPDATE} device={currentDevice} onClose={hide} />,
            actionText: t("Save"),
            cancelText: t("Cancel"),
            cancelCallback: trackCloseEditPatientDetailsModalXButton
        };

        trackOpenEditPatientDetailsModal()
        showDialog(args);
    };

    const sendFamilyMessage = async () => {     
        if (!(familyMessageDisabled || buttonsDisabled) && params.deviceId) {
            setNotificationTimer();
            setSendingFamilyMessage(true);
            setButtonsDisabled(true);
            AmplifyService.log(`sendFamilyMessage to ${params.deviceId}`);
            await communicationApi.post("sendCommunication", {
                deviceId: params.deviceId,
                communicationTypeId: communicatonType.SEND_FAMILY_MESSAGE,
                source: SenderSource.TABLET
            });
            trackSendingFamilyMessage()
        }
    };

    const setNotificationTimer = () => {
        const timeoutId = setTimeout(() => {
            removeSpinner();
            setButtonsDisabled(false);
            showToast({
                severity: TOAST_SEVERITY.ERROR,
                summary: t("Error"),
                detail: t("Your request couldn't be completed"),
            })
        }, 30000);
        setTimeOutId(timeoutId);
    }

    const sendPersonalMusic = async () => {
        if (!buttonsDisabled && params.deviceId) {
            setNotificationTimer();
            setSendingPersonalMusic(true);
            setButtonsDisabled(true);
            AmplifyService.log(`sendPersonalMusic to ${params.deviceId}`);
            await communicationApi.post("sendCommunication", {
                deviceId: params.deviceId,
                communicationTypeId: communicatonType.SEND_PERSONAL_MUSIC,
                source: SenderSource.TABLET
            });
            trackSendingMusic()
        }
    };

    const sendRelaxingMusic = async () => {
        if (!(relaxingMessageDisabled || buttonsDisabled) && params.deviceId) {
            trackSendingRelaxingMessage()
            setNotificationTimer();
            setSendingRelaxingMessage(true);
            setButtonsDisabled(true);
            AmplifyService.log(`sendRelaxingMusic to ${params.deviceId}`);
            await communicationApi.post("sendCommunication", {
                deviceId: params.deviceId,
                communicationTypeId: communicatonType.SEND_GENERAL_MESSAGE,
                source: SenderSource.TABLET,
                subOption: -1
            });
        }
    }

    const findCurrentDevice = (deviceId: string | undefined) => {
        if (!deviceId && devices && devices[0]) {
            navigate(`/nearbedview/${devices[0].id}`);
        } else {
            return devices?.find((device) => {
                return device.id === deviceId;
            });
        }
    };

    const getLanguage = (voiceId: number | undefined) => {
        if(!voiceId) { 
            return "";
        }
        const voice = voices.find( voice => voice.id === voiceId);
        return voice?.language.name || "";
    }

    const openVideoStream = () => {
        videoStreamRef.current.show();
    }

    const hideVideoStream = () => {
        videoStreamRef.current?.hide();
    }

    const _reconnectSchedulerEventTopic = (
        tenantId: string, icuId: string
    ) => {
        setTimeout(() => { 
            subscribeToSchedulerEventTopic(tenantId, icuId);
        }, 10000);
    };

    const _reconnectDeviceGenericTopic = (
        tenantId: string, icuId: string, deviceId: string
    ) => {
        setTimeout(() => { 
            subscribeToGenericDeviceTopic(tenantId, icuId, deviceId);
        }, 10000);
    };

    const subscribeToSchedulerEventTopic = (tenantId: string, icuId: string) => {
        const SCHEDULER_EVENT = "shcedulerevent";

        const topic = `${tenantId}/${icuId}/${SCHEDULER_EVENT}`;

        const schedulerSubscrition =  IotService.subscribeToTopic(
            topic,
            () => {
                fetchSchedulerEvents();
            },
            (err: any) => {
                console.log(`Error: ${JSON.stringify(err)}`);
            },
            () => {
                _reconnectSchedulerEventTopic(tenantId, icuId);
            }
        );

        setCurrentSchedulerSubscrition(schedulerSubscrition);

        return schedulerSubscrition;
    }

    const subscribeToGenericDeviceTopic = (tenantId: string, icuId: string, deviceId: string) => {
        const topic = `${tenantId}/${icuId}/${deviceId}/generic`;

        const deviceGenericSubscrition =  IotService.subscribeToTopic(topic,
            (data: any) => {
                if(data.value.action === GenericSubscriptionActions.CREATE) {
                    switch (data.value.resource) {
                        case GenericSubscriptionResources.FAMILY_MESSAGE:
                            setFamilyMessageDisabled(false)
                            return;
                        case GenericSubscriptionResources.RELAXING_MESSAGE:
                            setRelaxingMessageDisabled(false);
                            return;
                        default:
                            return;
                    }
                } else if(data.value.action === GenericSubscriptionActions.UPDATE) {
                    switch (data.value.resource) {
                        case GenericSubscriptionResources.PROFILE_IMAGE:
                            setTimeout(async () => {
                                const retries = 3; 
                                const image = await getS3ItemLink(`${tenantId}/${data.value.payload.patientId}/images/profile.jpg`, retries);
                                if(image) {
                                    updatePatientImage(deviceId, image)
                                }
                            }, 30000);
                            return;
                        default:
                            return;
                    }
                } else if(data.value.action === GenericSubscriptionActions.DELETE) {
                    switch (data.value.resource) {
                        case GenericSubscriptionResources.RELAXING_MESSAGE:
                            setRelaxingMessageDisabled(true);
                            return;
                        default:
                            return;
                    }
                }

            },
            (err: any) => {
                console.log(`Error: ${JSON.stringify(err)}`);
            },
            () => {
                _reconnectDeviceGenericTopic(tenantId, icuId, deviceId);
            }
        );

        return deviceGenericSubscrition;
    }

    const checkIfUnreadNotificationExists = (notifications: NotificationModel[] | undefined): boolean =>
        notifications !== undefined && 
            notifications.some(notification => notification.configurations.isRead === false)
    

    const updateNotificationsStatus = async (patientId: string | undefined, notifications: NotificationModel[] | undefined)=> {
        if (notifications) {
            let filter = notifications.filter(n =>
                n.configurations.isRead === false &&  n.configurations.notification === NOTIFICATIONS.Q_AND_A_RESULTS);

            if(filter.length > 0 && patientId) {
                const setFilter = filter.map(response => {
                    return {
                        ...response,
                        configurations: {
                            ...response.configurations,
                            isRead: false
                        }
                    }
                })
                await NotificationService.updateNotificationStatus(patientId, setFilter);
            }
            setUnreadNotification(false);
        }
    }
    
    useEffect(() => {
        if (tenantId && icuId) {
            let subscription = subscribeToSchedulerEventTopic(tenantId, icuId);
            return () => {
                if (currentSchedulerSubscrition) {
                    subscription = currentSchedulerSubscrition;
                }

                IotService.unsubscribeToTopic(subscription);
            }
        }
    }, [tenantId, icuId]);

    useEffect(() => {
        async function getProfileImage(device: DeviceModel) {
            const image = await getS3ItemLink(`${device.tenant_id}/${device.patient?._id}/images/profile.jpg`);
            
            if(image && device.patient?.profile_image !== image) {
                updatePatientImage(device.id, image);
            }
        }  
        let device = findCurrentDevice(params.deviceId);

        if (device && devices) {
            setCurrentDevice(device);
            getProfileImage(device);
        }
    }, [currentDevice?.patient]);

    useEffect(() => {
        let device = findCurrentDevice(params.deviceId);

        if (device && devices) {
            setCurrentDevice(device);
        }
    }, [currentDevice?.notifications.length]);

    useEffect(() => {
        let device = findCurrentDevice(params.deviceId);

        if (device && devices && device?.id !== currentDevice?.id) {
            const logs = device.communicationLogs ? device.communicationLogs : []

            device = {
                ...device,
                communicationLogs: logs
            }
            setCurrentDevice(device);
            setLanguage(getLanguage(device.shadow.settings.patient_voice_id));
            if (device.patient && featureFlags?.recordings?.family_recordings_management) {
                fetchFamilyRecordings(device.patient._id as string)
                fetchRecordingSlots(device.patient._id as string);
            }
            // if (sortedLogsDevice.patient && featureFlags?.recordings?.general_recordings_management) {
            //     fetchGeneralRecordings(sortedLogsDevice.patient._id as string);
            //     fetchRecordingSlots(sortedLogsDevice.patient._id as string);
            // }
            hideVideoStream();
        }
    }, [params.deviceId, devices?.length]);

    useEffect(() => {
        setLanguage(getLanguage(currentDevice?.shadow.settings.patient_voice_id));
    }, [currentDevice?.shadow.settings.patient_voice_id]);

    useEffect(() => {
        setIsResponsesEmpty(!checkIfResponsesEmpty(currentDevice?.notifications))
        setUnreadNotification(checkIfUnreadNotificationExists(currentDevice?.notifications))
    }, [currentDevice?.notifications.length]);

    useEffect(() => {
        let subscription: ZenObservable.Subscription;
        if(tenantId && icuId && params.deviceId){
            subscription = subscribeToGenericDeviceTopic(tenantId, icuId, params.deviceId);
        }
        
        return () => {
            if(subscription) {
                IotService.unsubscribeToTopic(subscription);
            }
            resetAttentionTimeOut();
        };
    }, [tenantId, icuId, params.deviceId]);

    useEffect(() => {
        let device = findCurrentDevice(params.deviceId);

        if (device && devices && device.patient?._id) {
            const patientRecordings = recordings?.get(device.patient._id);
            const familyMessages = patientRecordings?.familyRecordings
            if (familyMessages && familyMessages.length > 0 ) {
                setFamilyMessageDisabled(false);
            } else {
                setFamilyMessageDisabled(true);
            }

            const slots = patientRecordings?.slots;
            
            if (slots && slots.find(slot => slot.type === "Relaxing" && slot.recording_id !== "")) {
                setRelaxingMessageDisabled(false);
            } else {
                setRelaxingMessageDisabled(true);
            }
        }
    }, [params.deviceId, recordings, showDetachedDevice]);

    useEffect(() => {
        if (
            !(currentDevice?.shadow.state.active_communication_info?.id_type === NONE) ||
            currentDevice?.shadow.state.presence?.state === DeviceConnectionStatus.DISCONNECTED ||
            currentDevice?.shadow.state.is_healthy === false || 
            currentDevice?.shadow.state.device_state !== 1
        ) { 
            setButtonsDisabled(true);
        } else {
            setButtonsDisabled(false);
        }
    }, [
        currentDevice?.shadow.state.active_communication_info?.id_type,
        currentDevice?.shadow.state.presence?.state,
        currentDevice?.shadow.state.is_healthy,
        showDetachedDevice
    ]);

    useEffect(() => {
        checkAndOpenAttentionDialog();
    }, [
        currentDevice?.shadow.state?.presence?.state,
        currentDevice?.shadow?.state?.presence?.disconnection_reason,
        currentDevice?.shadow?.state?.is_healthy,
        currentDevice?.shadow?.state?.oop,
        currentDevice?.shadow?.settings?.oop_device_indication,
        currentDevice?.shadow?.state?.pwr
    ]);

    useEffect(() => {
        if(currentDevice && 
            currentDevice.communicationLogs &&
            currentDevice.communicationLogs[currentDevice.communicationLogs.length - 1]
        ) {
            const currentLogText = currentDevice?.communicationLogs[currentDevice.communicationLogs.length - 1].text;
            const currentLogTime = currentDevice?.communicationLogs[currentDevice.communicationLogs.length - 1].time;
            const source = currentDevice?.communicationLogs[currentDevice.communicationLogs.length - 1].source;
            if(!isLogsInitiate) {
                setIsLogsInitiate(true);
                return;
            }
            if ( source == SenderSource.TABLET &&
                (isSent(currentLogText) || 
                    (currentLogTime !== lastLogTime && isStopped(currentLogText)))
                ) {
                removeSpinner();
                clearTimeout(timeoutId);
            } else if (source == SenderSource.TABLET && isNotSent(currentLogText)) {
                removeSpinner();
                clearTimeout(timeoutId);
                showToast({
                    severity: TOAST_SEVERITY.ERROR,
                    summary: t("Error"),
                    detail: t("Your request couldn't be completed"),
                })

                setButtonsDisabled(false);
            }
            setLastLogTime(currentLogTime);
        }
    }, [currentDevice?.communicationLogs?.length])

    const removeSpinner = () => {
        if (sendingFamilyMessage) {
            setSendingFamilyMessage(false);
        } else if(sendingPersonalMusic){ 
            setSendingPersonalMusic(false);
        } else if(sendingRelaxingMessage) {
            setSendingRelaxingMessage(false);
        } else { 
            setSendingFamilyMessage(false);
            setSendingPersonalMusic(false);
            setSendingRelaxingMessage(false);
        }
    }

    const isSent = (log: string) => {
        return log.includes("was sent");
    }

    const isStopped = (log: string) => {
        return log.includes("was stopped by");
    }

    const isNotSent = (log: string) => {
        return log.includes("Device is unavailable") || log.includes("Patient is unavailable");
    }

    const checkIfResponsesEmpty = (notifications: NotificationModel[] | undefined) => {
        if (notifications) {
            return notifications.some(notification => 
                notification.configurations.notification === NOTIFICATIONS.Q_AND_A_RESULTS)
        }

        return true
    }

    const checkAndOpenAttentionDialog = () => {
        const communicationErrorType: CommunicationErrorType =
            getDeviceConnectionError(currentDevice?.shadow || ({} as DeviceShadowModel));

        resetAttentionTimeOut();

        if (communicationErrorType !== CommunicationErrorType.NONE) {
            openAttentionDialog(communicationErrorType);
        } else {
            hideAttentionDialog();
        }
    };

    const responsesCancelCallback = React.useCallback(async () => {
        trackCloseResponseModal()
        await updateNotificationsStatus(currentDevice?.patient?._id, currentDevice?.notifications);
        updateQNAStatus(currentDevice);
    }, [currentDevice?.notifications.length]);

    const openResponsesDialog = () => {
        const args: OpenDialogArgs = {
            dialogType: DialogTypes.RESPONSES,
            component: (
                <div className="responses-content">
                    <Responses timeZone={timeZone} deviceId={params.deviceId}/>
                </div>
            ),
            cancelCallback: responsesCancelCallback
        };

        trackOpenResponseModal()
        showDialog(args);
    };

    const openAttentionDialog = (communicationErrorType: CommunicationErrorType) => {
        const trackFunctionConfig = {
            [CommunicationErrorType.DEVICE_OFF]: trackCloseDeviceOffAlert,
            [CommunicationErrorType.CONNECTION_ERROR]: trackCloseWifiDisconnectedAlert,
            [CommunicationErrorType.POWER_NOT_CONNECTED]: trackClosePowerDisconnectedAlert,
            [CommunicationErrorType.TECHNICAL]: trackCloseTechnicalIssueAlert,
            [CommunicationErrorType.OOP]: () => {},
            [CommunicationErrorType.NONE]: () => {},
        };

        const args: OpenDialogArgs = {
            dialogType: DialogTypes.ATTENTION_DIALOG,
            component: (
                <div className="attention-content attention-content__dialog">
                    <CommunicationErrorIcon communicatoinErrorType={communicationErrorType}/>
                    <div className="attention-content__text center">
                        {t(mapCommunicationErrorTypeToString(communicationErrorType))}
                    </div>
                </div>
            ),
            cancelCallback: trackFunctionConfig[communicationErrorType]
        };

        showAttentionDialog(args);
    };

    const updateStreamStatus = (isVideoStreamReady: boolean) => {
        setIsVideoStreamReady(isVideoStreamReady);
    };

    const activeCommunication: ActiveCommunicationType | undefined =
        currentDevice?.shadow?.state?.active_communication_info?.id_type ?? NONE;

    const shadowState = currentDevice?.shadow?.state;

    const subTypeId: number | undefined =
    currentDevice?.shadow?.state?.active_communication_info?.sub_type_info?.slot_number;

    const renderEventWithPlayButton = (communicationTypeId: ActiveCommunicationType, onButtonClick: () => void, isSending: boolean, duration = '', isDisabled: boolean, questionName?: string) => (
        <>
            <div className="near-bed-view-page__icon">
                {
                    communicationTypeId === GENERAL_MESSAGE ?
                        <CommunicationIcon subOption={-1} width="4rem" height="4rem" communicatoinType={communicationTypeId}
                            wrapSize={WrapSize.MEDIUM}/>    
                    :

                        <CommunicationIcon width="4rem" height="4rem" communicatoinType={communicationTypeId}
                            wrapSize={WrapSize.MEDIUM}/>
                }
            </div>
            <div className={`near-bed-view-page__line near-bed-view-page__line--${communicationTypeId}`}/>
            <div className="near-bed-view-page__information-wrapper">
                <div className="near-bed-view-page__information">
                    <div className="near-bed-view-page__text">
                        {
                            communicationTypeId === GENERAL_MESSAGE ?
                                "Relaxing Message"
                            :
                                mapCommunicationTypeToStringPlayButtons(communicationTypeId, questionName)
                        }
                        <div className="near-bed-view-page__text__duration">{duration}</div>
                    </div>
                </div>
                <Button
                    onClick={onButtonClick}
                    className={`patient-action-menu__action-button button button-regular ${isDisabled ? "button-disabled" : ""}`}
                    disabled={isDisabled}
                >
                    {isSending ?
                        <div className="button-loading__near-bed-action">
                            <ProgressSpinner/>
                        </div>
                        :
                        <div className="near-bed-view-page__play-button">
                            <PlayIcon/>
                            <div className="near-bed-view-page__play-button-text">
                                {t("Play")}
                            </div>
                        </div>
                    }
                </Button>
            </div>
        </>
    )

    return (
        <div className="near-bed-view-page">
            {showDetachedDevice ? (
                <div className="near-bed-view-page__detached-device">
                    <NoPatientPage deviceId={detachedDeviceId} setShowDetachedDevice={setShowDetachedDevice}/>
                </div>
            ) : (
                <div className="near-bed-view-page">
                    {
                        isSmallTablet 
                        ?
                            null
                        :
                            <div className="near-bed-view-page__header-box">
                                {devices?.length ? (
                                    <div className="near-bed-view-page__header-buttons">
                                        <div className="near-bed-view-page__right-buttons">
                                            <button
                                                disabled={isVideoStreamReady}
                                                onClick={openVideoStream}
                                                className={`button button-regular button-camera button-near-bed-left ${
                                                    isVideoStreamReady ? "button-camera--disabled" : ""
                                                }`}
                                            >
                                                <CameraIcon color="#fff" />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    if (!isResponsesEmpty) {
                                                        openResponsesDialog();
                                                        updateNotificationsStatus(currentDevice?.patient?._id, currentDevice?.notifications);
                                                    }
                                                }}
                                                className={`button near-bed-view-page__button-responses button-responses button-near-bed-left ${
                                                    isResponsesEmpty ? "button-camera--disabled" : ""
                                                }`}
                                            >
                                                <div className="near-bed-view-page__responses-icons">
                                                    <PatientIcon />
                                                    {
                                                    unreadNotification ? 
                                                            <RedDotIcon className="near-bed-view-page__responses-red-dot-icon" height="9" width="9" />
                                                        :   <></>
                                                    }
                                                </div>
                                                <div className="near-bed-view-page__responses">
                                                    {t("Patient's Responses")}
                                                </div>
                                            </button>
                                        </div>
                                        <div className="near-bed-view-page__action-buttons">
                                            <PetientActionMenu featureFlags={featureFlags} deviceId={params.deviceId} />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                    }
                    {shadowState ? (
                    <div className="near-bed-view-page__content">
                        <span className="near-bed-view-page__right">
                            <div className="near-bed-view-page__profile near-bed-view-page__content-wrapper">
                                {isSmallTablet ? 
                                    <div className="near-bed-view-page__buttons near-bed-view-page__buttons-small-tablet">
                                        <button
                                            onClick={() => {
                                                if (!isResponsesEmpty) {
                                                    openResponsesDialog();
                                                    updateNotificationsStatus(currentDevice?.patient?._id, currentDevice?.notifications);
                                                }
                                            }}
                                            className={`button button-regular button-responses-small-tablet button-responses button-near-bed-left ${
                                                isResponsesEmpty ? "button-camera--disabled" : ""
                                            }`}
                                        >
                                            <div className="near-bed-view-page__responses-icons">
                                                <ResponsesPatientIcon />
                                                {
                                                unreadNotification ? 
                                                        <RedDotIcon className="near-bed-view-page__responses-red-dot-icon" height="9" width="9" />
                                                    :   <></>
                                                }
                                            </div>
                                            <div className="near-bed-view-page__responses near-bed-view-page__responses-small-tablet">
                                                {t("Responses")}
                                            </div>
                                        </button>
                                        <button
                                            disabled={isVideoStreamReady}
                                            onClick={openVideoStream}
                                            className={`button button-regular button-camera button-near-bed-left ${
                                                isVideoStreamReady ? "button-camera--disabled" : ""
                                            }`}
                                        >
                                            <CameraIcon height="3.5rem" width="3.5rem" color="#fff" />
                                        </button>
                                    </div>
                                :
                                    <div className="near-bed-view-page__buttons">
                                        <div className="near-bed-view-page__edit-button" onClick={() =>  editPatientsDetails()}>
                                            <EditButtonIcon />
                                        </div>
                                    </div>
                                }
                                <UserProfile 
                                    profile={currentDevice.patient?.profile_image}
                                    language={language}
                                    currentDevice={currentDevice}
                                />
                            </div>
                            <div className="near-bed-view-page__share-link near-bed-view-page__content-wrapper">
                                <ShareLink />
                            </div>
                        </span>
                        <div className="near-bed-view-page__communications">
                                {
                                    activeCommunication !== NONE && activeCommunication !== STOP_COMMUNICATION ? (
                                        <div className="near-bed-view-page__communications--top near-bed-view-page__content-wrapper near-bed-view-page__content-wrapper--end">
                                            <CurrentCommunicationView
                                                deviceId={currentDevice?.id}
                                                activeCommunicationId={activeCommunication}
                                                subTypeId={subTypeId}
                                                showDialog={showDialog}
                                                currentDevice={currentDevice}
                                                // recordings={recordings}
                                            />
                                        </div>
                                    )
                                    : 
                                    <div className="near-bed-view-page__content-wrapper near-bed-view-page__buttons-content near-bed-view-page__content-wrapper--end">
                                        <div className="near-bed-view-page__title-wrapper">
                                            <div className="near-bed-view-page__title">
                                                Play to {currentDevice?.patient?.first_name}
                                            </div>
                                        </div>
                                        <div className="near-bed-view-page__item near-bed-view-page__item-top">
                                            {renderEventWithPlayButton(GENERAL_MESSAGE, sendRelaxingMusic, sendingRelaxingMessage, relaxingMessageDuration, relaxingMessageDisabled || buttonsDisabled)}
                                        </div>
                                        <div className="near-bed-view-page__item near-bed-view-page__item-top">
                                            {renderEventWithPlayButton(MUSIC, sendPersonalMusic, sendingPersonalMusic, musicDuration, buttonsDisabled, currentDevice.shadow.state.active_communication_info?.sub_type_info?.questionName)}
                                        </div>
                                        <div className="near-bed-view-page__item near-bed-view-page__item-top">
                                            {renderEventWithPlayButton(FAMILY, sendFamilyMessage, sendingFamilyMessage, familyDuration, familyMessageDisabled || buttonsDisabled)}
                                        </div>
                                    </div>
                                }
                                <div
                                    className={`near-bed-view-page__content-wrapper near-bed-view-page__content-wrapper--end near-bed-view-page__communications--bottom${
                                        activeCommunication === NONE || activeCommunication === STOP_COMMUNICATION ? "" : "-active"
                                    }`}
                                >
                                    <NearBedSchedulerView activeCommunication={activeCommunication}/>
                                </div>
                        </div>
                    </div>
                    ) : (
                        <Skeleton height="100%" className="overview__data skeleton" />
                    )}
                    <VideoStreamBox
                        ref={videoStreamRef}
                        tenantId={currentDevice?.tenant_id}
                        deviceId={currentDevice?.id}
                        updateStreamStatus={updateStreamStatus}
                    />
                </div>
            )}
        </div>
    );
};

export const NearBedViewPage = connect(mapStateToProps, mapDispatchToProps,)(_NearBedViewPage);