import React, { useContext } from 'react';
import { Button } from 'primereact/button';
import { NoPatientIcon } from 'src/icons/NoPatientIcon';
import { t } from 'i18next';
import { DialogTypes, OpenDialogArgs } from '../general/CustomDialog';
import { UpsertPatientForm } from '../forms/UpsertPatientForm';
import { PatientActions } from 'src/constans/patientActions';
import { DialogContext } from 'src/contexts/DialogContext';
import { DialogPropTypes } from 'src/contexts/types';
import { useMixpanel } from 'src/hooks/useMixpanel';

interface NoPatientPageProps {
  deviceId: string | undefined;
  setShowDetachedDevice: (show: boolean) => void;
}

const NoPatientPage: React.FC<NoPatientPageProps> = ({ deviceId, setShowDetachedDevice }) => {
    const { trackOpenAddPatientModal, trackCloseAddPatientModalXButton, trackSaveAddPatientModal } = useMixpanel()
    const { showDialog, hide } = useContext(DialogContext) as DialogPropTypes;
    
    const openAddPatientDialog = () => {
        const args: OpenDialogArgs = {
            dialogType: DialogTypes.FORM_DIALOG,
            title: t("Add new patient"),
            component: <UpsertPatientForm type={PatientActions.CREATE} onClose={hide} deviceId={deviceId} setShowDetachedDevice={setShowDetachedDevice}/>,
            actionText: t("Save"),
            cancelText: t("Cancel"),
            cancelCallback: trackCloseAddPatientModalXButton,
            actionCallback: trackSaveAddPatientModal
        };

        showDialog(args);
        trackOpenAddPatientModal()
    };

    return (
        <div className="no-patient-page">
            <div className="no-patient-icon">
                <NoPatientIcon />
            </div>
            <div className="no-patient-text">
                <div className='no-patient-text-title'>{t("No patient found")}</div>
                <div className='no-patient-text-content'>{t("Want to add a new patient?")}</div>
            </div>
            <Button
                onClick={openAddPatientDialog}
                className="button button-regular button-big no-patient-button"
            >
                {t("Add Patient")}
            </Button>
        </div>
    );
};

export default NoPatientPage;
